import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class BooksScene extends Scene {
  constructor() {
    super({ key: "BooksScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.books = data.books;
  }

  COLOR_PRIMARY = 0x4f4f4f;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
  }
  create() {
    this.totalRows = 0;
    this.add.rectangle(0, 0, 480, 80, 0x8f8f8f).setOrigin(0);

    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.bookText = this.add
      .text(240, 40, "Books", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 42,
        color: "#000000",
      })
      .setOrigin(0.5);

    // scroll continaer
    const scrollablePanel = this.rexUI.add
      .scrollablePanel({
        x: 240,
        y: 467,
        width: 480,
        height: 770,

        scrollMode: 0, // 0: vertical, 1: horizontal

        panel: {
          child: this.createGrid(),
          mask: { padding: 1 },
        },

        slider: {
          track: this.rexUI.add.roundRectangle(
            0,
            0,
            20,
            10,
            10,
            this.COLOR_DARK
          ),
          thumb: this.add.image(0, 0, "imgThumb"),
        },

        space: {
          left: 30,
          right: 30,
          top: 0,
          bottom: 0,

          panel: {
            top: 20,
            bottom: 20,
            left: 0,
            right: 20,
          },
          slider: {
            top: 20,
            bottom: 20,
            left: 0,
            right: 0,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },

        // Other configurations...
      })
      .layout();

    scrollablePanel
      .setChildrenInteractive({
        targets: [
          scrollablePanel.getByName("gridSizerOwn", true),
          scrollablePanel.getByName("gridSizerNotOwn", true),
        ],
        targetMode: "direct",
      })
      .on("child.click", (child) => {
        this.readBook(child.book);
      })
      .on("child.over", (child) => {
        child.getElement("cover").getElement("dark").setAlpha(1);
      })
      .on("child.out", (child) => {
        child.getElement("cover").getElement("dark").setAlpha(0);
      });

    if (this.totalRows * 250 < 650) {
      // scrollablePanel.setSliderEnable(false);
      // scrollablePanel.setScrollerEnable(false);
      // scrollablePanel.setMouseWheelScrollerEnable(false);
      scrollablePanel.on("scroll", (panel) => {
        panel.scrollToTop();
      });
    }
  }

  createGrid() {
    let bottom =
      this.books.length * 250 < 770 ? 770 - this.books.length * 250 : 0;

    const container = this.rexUI.add.sizer({
      orientation: "y",
      space: { bottom: 50 + bottom },
    });

    let [ownBooksArray, notOwnBooksArray] = this.books.reduce(
      (acc, obj) => {
        if (obj["PlayerBook"].length > 0) {
          acc[0].push(obj);
        } else {
          acc[1].push(obj);
        }
        return acc;
      },
      [[], []]
    );

    // ownBooksArray = this.books

    if (ownBooksArray.length > 0) {
      const containerTitleOwn = this.rexUI.add.sizer({
        orientation: "x",
      });

      containerTitleOwn.add(
        this.add.text(0, 0, "Books you own", {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 22,
          color: "#000",
        }),
        0,
        "center",
        { left: 10, right: 10 }
      );

      containerTitleOwn.add(this.add.rectangle(0, 0, 290, 1, 0x000000));

      container.add(containerTitleOwn, 0, "left", { bottom: 10 });

      const gridSizerOwn = this.rexUI.add
        .gridSizer({
          column: 2,
          row: Math.ceil(ownBooksArray.length / 2),
          columnProportions: 1,
          space: { column: 20, row: 20, bottom: 50 },
        })
        .setName("gridSizerOwn");

      this.totalRows += Math.ceil(ownBooksArray.length / 2);

      ownBooksArray.forEach((book, i) => {
        const item = this.createItem(book);
        gridSizerOwn.add(item, i % 2, Math.floor(i / 2), "center", 0, false);
      });

      container.add(gridSizerOwn, {
        proportion: 0,
        align: "center",
        padding: {
          left: ownBooksArray.length == 1 ? -85 : 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        expand: false,
        key: undefined,
        index: undefined,
        minWidth: undefined,
        minHeight: undefined,
        fitRatio: 0, // true
      });
    }

    if (notOwnBooksArray.length > 0) {
      const containerTitleBotOwn = this.rexUI.add.sizer({
        orientation: "x",
      });

      containerTitleBotOwn.add(
        this.add.text(0, 0, "Published books", {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 22,
          color: "#000",
        }),
        0,
        "center",
        { left: 10, right: 10 }
      );

      containerTitleBotOwn.add(this.add.rectangle(0, 0, 280, 1, 0x000000));

      container.add(containerTitleBotOwn, 0, "left", { bottom: 10 });

      const gridSizerNotOwn = this.rexUI.add
        .gridSizer({
          column: 2,
          row: Math.ceil(notOwnBooksArray.length / 2),
          columnProportions: 1,
          space: { column: 20, row: 10, bottom: 50 },
        })
        .setName("gridSizerNotOwn");

      this.totalRows += Math.ceil(notOwnBooksArray.length / 2);

      notOwnBooksArray.forEach((book, i) => {
        const item = this.createItem(book);
        gridSizerNotOwn.add(item, i % 2, Math.floor(i / 2), "center", 0, true);
      });
      container.add(gridSizerNotOwn, {
        proportion: 0,
        align: "center",
        padding: {
          left: notOwnBooksArray.length == 1 ? -85 : 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        expand: false,
        key: undefined,
        index: undefined,
        minWidth: undefined,
        minHeight: undefined,
        fitRatio: 0, // true
      });
    }
    return container;
  }

  createItem(book) {
    const container = this.rexUI.add.sizer({
      orientation: "y",
    });
    container.book = book;

    const containerImage = this.rexUI.add.overlapSizer();
    containerImage.add(this.add.image(0, 0, book.key).setDisplaySize(175, 250));
    if (book.PlayerBook.length > 0) {
      containerImage.add(
        this.add.image(0, 0, "imgPurchased").setDisplaySize(175, 250)
      );
    }

    const containerDark = this.rexUI.add.overlapSizer();
    containerDark.add(
      this.add.rectangle(0, 0, 175, 250, 0x000000).setAlpha(0.5)
    );
    containerDark.add(this.add.image(0, 0, "imgOkBtn").setScale(0.5), {
      key: undefined,
      align: "center",
      offsetX: 0,
      offsetY: 0,
      padding: { left: 0, right: 0, top: 0, bottom: 0 },
      expand: false,
      minWidth: undefined,
      minHeight: undefined,
      aspectRatio: 0,
    });

    containerDark.add(
      this.add
        .text(0, 0, book.PlayerBook.length > 0 ? "Read" : "Purchase", {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 28,
          color: "#000",
        })
        .setOrigin(0.5),
      {
        key: undefined,
        align: "center",
        offsetX: 0,
        offsetY: 0,
        padding: { left: 0, right: 0, top: 0, bottom: 0 },
        expand: false,
        minWidth: undefined,
        minHeight: undefined,
        aspectRatio: 0,
      }
    );
    containerDark.setAlpha(0);

    containerImage.add(containerDark, "dark");
    container.add(
      containerImage,
      0,
      "center",
      {
        bottom: 10,
        top: 5,
      },
      false,
      "cover"
    );

    if (book.PlayerBook.length > 0) {
      const containerBar = this.rexUI.add.overlapSizer();

      containerBar.add(
        this.rexUI.add.roundRectangle(0, 0, 150, 5, 6, 0x4f4f4f)
      );

      if (book.PlayerBook[0].totalStar > 0) {
        containerBar.add(
          this.rexUI.add.roundRectangle(
            0,
            0,
            144 *
              (book.PlayerBook[0].totalStar / ((book._count.Chapter - 1) * 3)),
            3,
            3,
            0x00ffff
          ),
          {
            align: "left",
            offsetX: 3,
            offsetY: 0,
            expand: false,
          }
        );
      }

      const containerCaption = this.rexUI.add.sizer({
        orientation: "x",
      });

      containerCaption.add(
        this.add.image(0, 0, "imgStar").setScale(0.25).setDepth(5)
      );
      containerCaption.add(
        this.add.text(
          0,
          0,
          book.PlayerBook[0].totalStar + "/" + (book._count.Chapter - 1) * 3,
          {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 18,
            color: "#000",
          }
        ),
        0,
        "center",
        { left: 10 }
      );

      container.add(containerBar, 0, "left", { bottom: 10, left: 15 });
      container.add(containerCaption, 0, "center", { bottom: 5 });
    }
    return container;
  }
  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    router.push({
      name: "PageExploreLanding",
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BooksScene",
    });
  }
  readBook(book) {
    this.scene.stop("BooksScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("BookIntroScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      book: book,
      books: this.books,
    });
  }
  update() {}
}
