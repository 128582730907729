import { Scene } from "phaser";
// import Phaser from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
import _ from "lodash";
// import axios from "axios";

export default class QScene extends Scene {
  constructor() {
    super({ key: "QScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.deck = data.deck;
    this.flashCard = data.flashCard;
    this.flashcards = data.flashcards;
    this.env = data.env;
  }
  preload() {
    for (var key of this.deck) {
      this.load.image(key.Vocab.key, key.Vocab.imageUrl);
    }
  }

  selectedDeckWord = -1;
  allDeck = [];
  selectedDeck = {};
  isAnswered = false;
  options = [];
  score = 0;
  type = "fillblank";
  // types = ["chooseone", "fillblank"];
  types = ["fillblank"];
  chars = [
    ["A", "B", "C", "D", "E", "F", "G"],
    ["H", "I", "J", "K", "L", "M", "N"],
    ["O", "P", "Q", "R", "S", "T", "U"],
    ["V", "W", "X", "Y", "Z", "<-"],
  ];
  lastFilledIndex = [];
  create() {
    this.add.rectangle(240, 427, 480, 854, 0xffffff);
    console.log("hello", this.flashCard);
    this.lastFilledIndex = [];
    this.simpleClickSound = this.sound.add("sfxSimpleClick");
    this.type = _.sample(this.types);
    if (this.type == "chooseone") {
      this.getDec();
    } else if (this.type == "fillblank") {
      this.getFillBlank();
    }

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.okBtn = this.add.image(380, 750, "imgOkBtn").setScale(0).setDepth(3);
    this.okBtnTxt = this.add
      .text(380, 750, i18n.t("miniGames.general.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 42,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(3);

    this.okBtn.setInteractive({ cursor: "pointer" });

    // Sounds
    this.sfxCorrectAnswer = this.sound.add("sfxCorrectAnswer");
    this.sfxWrongAnswer = this.sound.add("sfxWrongAnswer");
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");

    this.okBtn.on(
      "pointerdown",
      () => {
        this.sfxSimpleClick.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxSimpleClick.play();
        this.avatarObject = [];
        this.isAnswered = false;
        this.selectedDeckWord = -1;
        this.allDeck = [];
        this.selectedDeck = {};
        this.isAnswered = false;
        this.options = [];
        this.score = 0;
        this.type = "fillblank";
        // types = ["chooseone", "fillblank"];
        this.types = ["fillblank"];
        this.chars = [
          ["A", "B", "C", "D", "E", "F", "G"],
          ["H", "I", "J", "K", "L", "M", "N"],
          ["O", "P", "Q", "R", "S", "T", "U"],
          ["V", "W", "X", "Y", "Z", "<-"],
        ];
        this.lastFilledIndex = [];
        this.registry.destroy();
        this.events.off();
        this.scene.stop("QScene");
        this.scene.resume("MapScene", {
          isCorrectAnswer: this.isCorrectAnswer,
        });
      },
      this
    );

    if (this.type == "chooseone") {
      let z = -1;
      for (let i = 0; i < 4; i++) {
        let x = 0;
        if (i % 2 == 0) {
          x = 20;
          z++;
        } else {
          x = 250;
        }
        let option = this.add
          .image(x, 460 + z * 80, "imgAnswerPanel")
          .setOrigin(0, 0.5)
          .setScale(1, 1.5);

        this.add
          .text(
            x + 20,
            460 + z * 80,
            String.fromCharCode(i + 65) +
              ") " +
              JSON.parse(this.options[i].translatableName)["en"],
            {
              fontFamily: "Sassoon, sans-serif",
              wordWrap: { width: 180 },
              fontSize: 18,
              color: "#000",
            }
          )
          .setOrigin(0, 0.5);

        option.isAnswer = this.options[i].isAnswer;
        option.setInteractive({ useHandCursor: true });

        if (option.isAnswer) {
          this.answer = this.add
            .image(x + 15, 445 + z * 80, "imgAnswerTag")
            .setScale(0.45);
          this.answer.visible = false;
        }

        option.on("pointerdown", () => {
          if (!this.isAnswered) {
            this.sfxSimpleClick.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxSimpleClick.play();
            this.isAnswered = true;
            this.answer.setTint(option.isAnswer ? 0x00ff00 : 0xff0000);
            this.answer.visible = true;
            if (option.isAnswer) {
              this.countCorrect += 1;
              this.add
                .image(option.x + 180, option.y, "imgCorrectMark")
                .setScale(0.5);
              this.okHamochi = this.add
                .image(380, 700, "imgCorrectAns")
                .setScale(0)
                .setDepth(2);
              this.isCorrectAnswer = true;
            } else {
              this.add
                .image(option.x + 180, option.y, "imgWrongMark")
                .setScale(0.5);
              this.okHamochi = this.add
                .image(380, 700, "imgWrongAns")
                .setScale(0)
                .setDepth(2);
              this.isCorrectAnswer = false;
            }
            this.tweens.add({
              targets: [this.okBtn, this.okBtnTxt, this.okHamochi],
              scaleX: 0.6,
              scaleY: 0.6,
              duration: 200,
              ease: "Sine.easeOut",
              delay: 500,
              onStart: () => {
                if (this.isCorrectAnswer) {
                  this.sfxCorrectAnswer.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxCorrectAnswer.play();
                  this.score = this.score + 1;
                  this.scoreText.setText("Score : " + this.score);
                } else {
                  this.sfxWrongAnswer.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxWrongAnswer.play();
                }
              },
            });
          }
        });
      }
    }

    // User input handling (assuming keyboard input)
    // var that = this;
    // document.addEventListener("keydown", function (event) {
    //   var inputLetter = String.fromCharCode(event.keyCode).toLowerCase();
    //   if (_.includes(that.selectedDeck.name, inputLetter)) {
    //       // Check if the input letter matches any of the missing letters
    //       for (var i = 0; i < that.displayWord.length; i++) {
    //           if (that.displayWord[i] === '_') {
    //             console.log(that.letterInputs[i], that.letterInputs)
    //               // Fill the input field with the correct letter
    //               that.letterInputs[i].getChildByName('input').value = inputLetter;
    //           }
    //       }

    //       // Check if the word is complete
    //       var filledWord = that.letterInputs.map(function(input) {
    //           return input.getChildByName('input').value || '_';
    //       }).join('');

    //       if (filledWord === that.selectedDeck.name) {
    //           // Player wins
    //           console.log("Congratulations! You've guessed the word: " + that.selectedDeck.name);
    //       }
    //   }
    // });
    this.scoreBoard = this.add.image(320, 40, "imgScorePanel").setScale(0.4);
    this.vocabBoard = this.add.image(150, 40, "imgScorePanel").setScale(0.4);

    this.scoreText = this.add
      .text(260, 40, "Score : " + this.score + "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 22,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.vocabText = this.add
      .text(90, 40, "Vocab : " + this.vocab + "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 22,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);
  }
  setKeyboard() {
    var startY = 350;
    var boxSize = 50;
    this.keyboardButtons = [];
    var buttonSize = 50;
    var keyboardX = 260 - (this.chars[0].length * (buttonSize + 3)) / 2;
    var keyboardY = startY + boxSize + 20;
    var that = this;
    for (var row = 0; row < this.chars.length; row++) {
      for (var col = 0; col < this.chars[row].length; col++) {
        let keyBox = this.add.rectangle(
          keyboardX + 5 + col * (boxSize + 3),
          keyboardY + 10 + row * (buttonSize + 3),
          boxSize,
          boxSize,
          "0xffffff"
        );
        keyBox.setStrokeStyle(2, 0x000000);
        var keyText = this.add.text(
          keyboardX - 2 + col * (buttonSize + 3),
          keyboardY + row * (buttonSize + 3),
          this.chars[row][col].toLowerCase(),
          { fontSize: "24px", fill: "#000" }
        );

        keyBox.setInteractive({ useHandCursor: true });

        if (this.chars[row][col] == "<-") {
          keyBox.setSize(boxSize * 2, boxSize);
          keyBox.setFillStyle(0xff0000);
          keyText.setX(keyboardX - 8 + col * (buttonSize + 8));
          keyBox.on("pointerdown", function (pointer, localX, localY, event) {
            console.log(pointer, localX, localY, event);
            that.removeLastLetter();
          });
        } else {
          keyBox.on(
            "pointerdown",
            this.fillLetter.bind(this, this.chars[row][col].toLowerCase()),
            this
          );
          // keyBox.on("pointerdown", function (pointer, localX, localY, event) {
          //   console.log(pointer, localX, localY, event);
          // //  var inputLetter = this.text.toUpperCase();
          // var inputLetter = that.chars[row][col].toUpperCase()
          //   that.fillLetter(inputLetter);
          // });
        }

        this.keyboardButtons.push(keyBox);
      }
    }

    // var backspaceButton = this.add.text(
    //   keyboardX,
    //   keyboardY + buttonSize * this.chars.length + 10,
    //   "<-",
    //   { fontSize: "24px", fill: "#000" }
    // );
    // backspaceButton.setInteractive();
    // backspaceButton.on(
    //   "pointerdown",
    //   function (pointer, localX, localY, event) {
    //     console.log(pointer, localX, localY, event);
    //     that.removeLastLetter();
    //   }
    // );
    // keyboardButtons.push(backspaceButton);
  }
  removeLastLetter() {
    this.checkBtn
      .setTint("0x808080")
      .off("pointerdown", this.checkAnswer, this)
      .removeInteractive();
    var len = this.lastFilledIndex.length;
    if (len != 0) {
      this.displayWord =
        this.displayWord.substr(0, this.lastFilledIndex[len - 1]) +
        "_" +
        this.displayWord.substr(this.lastFilledIndex[len - 1] + 1);
      this.letterInputs[this.lastFilledIndex[len - 1]].setText("_");
      this.lastFilledIndex.pop();
    }
    // for (var i = this.displayWord.length - 1; i >= 0; i--) {
    //   if (this.displayWord[i] !== " ") {
    //     // Remove the last entered letter
    //     this.displayWord =
    //       this.displayWord.substr(0, i) + "_" + this.displayWord.substr(i + 1);
    //     this.letterInputs[i].setText("_");
    //     break;
    //   }
    // }
  }
  fillLetter(inputLetter) {
    for (var k = 0; k < this.displayWord.length; k++) {
      if (this.displayWord[k] === "_") {
        this.displayWord =
          this.displayWord.substr(0, k) +
          inputLetter +
          this.displayWord.substr(k + 1);
        this.letterInputs[k].setText(inputLetter);
        this.lastFilledIndex.push(k);
        break;
      }
    }
    if (!_.includes(this.displayWord, "_")) {
      this.checkBtn
        .clearTint()
        .setInteractive({ cursor: "pointer" })
        .on("pointerdown", this.checkAnswer, this);

      // this.tweens.add({
      //   targets: [this.checkBtn, this.checkBtnTxt],
      //   scaleX: 0.6,
      //   scaleY: 0.6,
      //   duration: 200,
      //   ease: "Sine.easeOut",
      //   delay: 500,
      // });
    }
    // // Check if the word is complete
    // var filledWord = this.letterInputs.map(function(input) {
    //     return input.getChildByName('input').value || '_';
    // }).join('');

    // if (filledWord === this.selectedDeck.name) {
    //     // Player wins
    //     console.log("Congratulations! You've guessed the word: " + this.selectedDeck.name);
    // }
  }
  getRandomAnswers(correctAnswer, totalAnswers = 4) {
    const allDeckNames = this.allDeck;
    const answers = [correctAnswer.Vocab];
    const answersIds = [correctAnswer.Vocab.key];
    while (answers.length < totalAnswers) {
      const randomName =
        allDeckNames[Math.floor(Math.random() * allDeckNames.length)];
      randomName.isAnswer = false;
      if (!answersIds.includes(randomName.Vocab.key)) {
        answers.push(randomName.Vocab);
        answersIds.push(randomName.Vocab.key);
      }
    }
    return _.shuffle(answers);
  }
  getFillBlank() {
    this.checkBtn = this.add
      .image(380, 700, "imgOkBtn")
      .setScale(0.6)
      .setDepth(3)
      .setTint("0x808080");
    this.checkBtnTxt = this.add
      .text(380, 700, "CHECK", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 42,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(0.6)
      .setDepth(3);
    this.selectedDeckWord++;
    this.allDeck = _.cloneDeep(this.deck);
    this.selectedDeck = _.cloneDeep(this.allDeck[this.selectedDeckWord]);
    this.deckImg = this.add
      .image(240, 200, this.selectedDeck.Vocab.key)
      .setScale(0.6);

    this.hiddenLettersLength = _.random(
      1,
      JSON.parse(this.selectedDeck.Vocab.translatableName)["en"].length - 1
    );

    this.hiddenLettersIndex = _.random(
      0,
      JSON.parse(this.selectedDeck.Vocab.translatableName)["en"].length -
        this.hiddenLettersLength
    );
    this.displayWord =
      JSON.parse(this.selectedDeck.Vocab.translatableName)["en"].substring(
        0,
        this.hiddenLettersIndex
      ) +
      "_".repeat(this.hiddenLettersLength) +
      JSON.parse(this.selectedDeck.Vocab.translatableName)["en"].substring(
        this.hiddenLettersIndex + this.hiddenLettersLength
      );
    // this.text = this.add.text(100, 100, this.displayWord, {
    //   fontSize: "32px",
    //   fill: "#fff",
    // });

    this.letterInputs = [];
    var startX =
      240 - (this.displayWord.length * 30 + this.displayWord.length * 3) / 2;
    var startY = 300;
    var boxSize = 30;
    var textStyle = { fontSize: "20px", fill: "#000" };
    for (var i = 0; i < this.displayWord.length; i++) {
      var boxColor = this.displayWord[i] === "_" ? 0xffffff : 0xffffff;
      var box = this.add.rectangle(
        startX + i * (boxSize + 3),
        startY,
        boxSize,
        boxSize,
        boxColor
      );
      var text;

      if (this.displayWord[i] !== "_") {
        text = this.add.text(
          startX + i * (boxSize + 3) - boxSize / 2 + 8,
          startY - boxSize / 2 + 8,
          this.displayWord[i].toLowerCase(),
          textStyle
        );
      } else {
        // Create input field for missing letter
        //var  box = this.add.rectangle(startX + i * (boxSize + 5), startY, boxSize, boxSize, 0xffffff);
        if (
          JSON.parse(this.selectedDeck.Vocab.translatableName)["en"][i] === " "
        ) {
          this.displayWord =
            this.displayWord.substr(0, i) +
            " " +
            this.displayWord.substr(i + 1);
          text = this.add.text(
            startX + i * (boxSize + 3) - boxSize / 2 + 8,
            startY - boxSize / 2 + 8,
            " ",
            textStyle
          );
        } else {
          text = this.add.text(
            startX + i * (boxSize + 3) - boxSize / 2 + 8,
            startY - boxSize / 2 + 8,
            this.displayWord[i].toLowerCase(),
            textStyle
          );
          box.setStrokeStyle(2, 0x000000);
        }
      }

      this.letterInputs.push(text);
    }
    this.setKeyboard();
  }
  checkAnswer() {
    this.checkBtn.setVisible(false);
    this.checkBtnTxt.setVisible(false);
    this.keyboardButtons.forEach(function (button) {
      button.removeListener("pointerdown");
    });
    if (
      this.displayWord.toLowerCase() ==
      JSON.parse(this.selectedDeck.Vocab.translatableName)["en"].toLowerCase()
    ) {
      this.isCorrectAnswer = true;
      this.add
        .image(
          240 -
            ((this.displayWord.length - 1) * 30 + this.displayWord.length * 3) /
              2 +
            (this.displayWord.length - 1) * 33,
          330,
          "imgCorrectMark"
        )
        .setScale(0.5);
      this.okHamochi = this.add
        .image(380, 700, "imgCorrectAns")
        .setScale(0)
        .setDepth(2);
      // Player wins
    } else {
      this.isCorrectAnswer = false;
      this.add
        .image(
          240 -
            ((this.displayWord.length - 1) * 30 + this.displayWord.length * 3) /
              2 +
            (this.displayWord.length - 1) * 33,
          330,
          "imgWrongMark"
        )
        .setScale(0.5);
      this.okHamochi = this.add
        .image(380, 700, "imgWrongAns")
        .setScale(0)
        .setDepth(2);

      this.answerText = this.add
        .text(
          230,
          350,
          "Answer : " +
            JSON.parse(this.selectedDeck.Vocab.translatableName)[
              "en"
            ].toLowerCase() +
            "",
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 22,
            color: "#492a12",
          }
        )
        .setOrigin(0, 0.5);

      let textWidth = this.answerText.width;
      this.answerText.setX(240 - textWidth / 2);
      console.log(
        "Not win" + JSON.parse(this.selectedDeck.Vocab.translatableName)["en"]
      );
    }
    this.tweens.add({
      targets: [this.okBtn, this.okBtnTxt, this.okHamochi],
      scaleX: 0.6,
      scaleY: 0.6,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onStart: () => {
        if (this.isCorrectAnswer) {
          this.sfxCorrectAnswer.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxCorrectAnswer.play();
          this.score = this.score + 1;
        } else {
          this.sfxWrongAnswer.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxWrongAnswer.play();
        }
      },
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "QScene",
    });
  }
  getDec() {
    this.selectedDeckWord++;
    this.allDeck = _.cloneDeep(this.deck);
    this.selectedDeck = _.cloneDeep(this.allDeck[this.selectedDeckWord]);
    this.selectedDeck.Vocab.isAnswer = true;
    this.options = this.getRandomAnswers(this.selectedDeck);

    this.deckImg = this.add
      .image(240, 200, this.selectedDeck.Vocab.key)
      .setScale(0.6);
  }
  playDeck(deck) {
    var speech = new SpeechSynthesisUtterance();
    speech.text = deck.name;
    window.speechSynthesis.speak(speech);
  }
  update() {
    // this.progressBar.rotation += 0.01;
  }
}
