import { Scene } from "phaser";

export default class FightScene extends Scene {
  constructor() {
    super({ key: "FightScene" });
  }

  init(data) {
    this.avatar = data.avatar;
  }

  playerAvatarObject = [];

  create() {
    this.add.rectangle(240, 427, 480, 854, 0xffffff);
    this.add
      .text(
        240,
        400,
        "This will be a fight scene with monstor or enemy. Reuse BiBaBo.",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 24,
          color: "#000",
          wordWrap: { width: 300 },
          align: "center",
        }
      )
      .setOrigin(0.5)
      .setDepth(9);

    this.okBtn = this.add
      .image(240, 500, "imgOkBtn")
      .setScale(0.8, 0.6)
      .setDepth(9);
    this.okBtnTxt = this.add
      .text(240, 500, "Fight and Win", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(9);
    this.okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        this.scene.stop("FightScene");
        this.scene.resume("MapScene", {
          scene: "fight",
          isWin: true,
        });
      },
      this
    );

    this.okBtn2 = this.add
      .image(240, 570, "imgOkBtn")
      .setScale(0.8, 0.6)
      .setDepth(9);
    this.okBtnTxt2 = this.add
      .text(240, 570, "Fight and Loose", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(9);
    this.okBtn2.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        this.scene.stop("FightScene");
        this.scene.resume("MapScene", {
          scene: "fight",
          isWin: false,
        });
      },
      this
    );
  }
}
