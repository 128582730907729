import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";

export default class StoryScene extends Scene {
  constructor() {
    super({ key: "StoryScene" });
    this.autoNextChapter = true;
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.chapter = data.chapter;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.bookPage = this.add
      .image(240, 380, "img" + this.currentStep.Page.key)
      .setDisplaySize(420, 600);
    this.button = this.add
      .sprite(240, 640, "audioButton", 1)
      .setInteractive({ useHandCursor: true });
    this.button.on("pointerdown", this.handleButtonClick, this);

    // this.chapterText = this.add
    //   .text(40, 80, this.chapter.key, {
    //     fontFamily: "MouseMemoirs, sans-serif",
    //     fontSize: 22,
    //     color: "#000000",
    //   })
    //   .setOrigin(0, 0.5);

    // this.titleText = this.add
    //   .text(40, 100, this.chapter.name, {
    //     fontFamily: "MouseMemoirs, sans-serif",
    //     fontSize: 24,
    //     color: "#000000",
    //   })
    //   .setOrigin(0, 0.5);

    this.generateStory(this.currentStep.Page);
  }

  handleButtonClick() {
    this.autoNextChapter = false; // Cancel automatic next chapter
    this.countdownText.setVisible(false); // Hide countdown when button is clicked
    if (this.button.frame.name === 1) {
      this.button.setFrame(0); // Set to play icon
      this.play();
    } else if (this.button.frame.name === 0) {
      this.button.setFrame(2); // Set to stop icon
      this.pause();
    } else if (this.button.frame.name === 2) {
      this.button.setFrame(0); // Set to pause icon
      this.resume();
    }
  }

  play() {
    if (!this.audio || !this.audio.isPlaying) {
      this.readStoryAloud(this.currentStep.Page);
    } else {
      this.audio.resume();
    }
  }

  pause() {
    if (this.audio && this.audio.isPlaying) {
      this.audio.pause();
    }
  }

  resume() {
    if (this.audio && !this.audio.isPlaying) {
      this.audio.resume();
    }
  }

  generateStory(story) {
    this.storyText = story.content;
    const isMandarin = /[\u3400-\u9FBF]/.test(this.storyText);
    this.storyWords = isMandarin
      ? this.storyText.split("")
      : this.storyText.split(" ");
    let x = 40;
    let y = 700;
    const lineHeight = 25;
    const maxWidth = 400; // Maximum width of the text container
    let currentLine = [];
    let currentLineWidth = 0;

    this.storyWords.forEach((word) => {
      let textObject = this.add.text(0, 0, word, {
        fontFamily: "WarungKopi, sans-serif",
        fontSize: "22px",
        fill: "#000",
        align: "left", // Align text to the left
      });
      if (
        currentLineWidth +
          textObject.width +
          (currentLine.length > 0 ? 10 : 0) >
        maxWidth
      ) {
        // Left-align the current line
        let currentX = x;
        currentLine.forEach((obj) => {
          obj.x = currentX;
          obj.y = y;
          currentX += obj.width + 10;
        });

        y += lineHeight;
        currentLine = [];
        currentLineWidth = 0;
      }

      currentLine.push(textObject);
      currentLineWidth += textObject.width + (currentLine.length > 1 ? 10 : 0);
    });

    // Left-align the last line
    let currentX = x;
    currentLine.forEach((obj) => {
      obj.x = currentX;
      obj.y = y;
      currentX += obj.width + 10;
    });

    this.okBtn = this.add
      .image(400, 810, "imgOkBtn")
      .setScale(0.5)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.nextStep, this);

    this.okBtnTxt = this.add
      .text(398, 810, "CONTINUE", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 22,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);

    this.countdownText = this.add
      .text(330, 810, "", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1)
      .setVisible(false); // Hide initially

    this.time.delayedCall(1000, () => {
      this.play();
      this.button.setFrame(0);
    });
  }

  readStoryAloud(story) {
    this.audio = this.sound.add("sfx" + story.key);
    this.audio.play();

    this.audio.on("complete", () => {
      this.time.delayedCall(1000, () => {
        this.button.setFrame(1); // Set to play icon when highlighting is complete
      });

      this.autoNextChapter = true;
      let countdown = 5;
      this.countdownText.setVisible(true);
      this.countdownText.setText(countdown.toString());

      this.countdownEvent = this.time.addEvent({
        delay: 1000,
        callback: () => {
          countdown--;
          this.countdownText.setText(countdown.toString());
          if (countdown <= 0) {
            this.countdownEvent.remove(false);
            this.countdownText.setVisible(false);
            if (this.autoNextChapter) {
              this.nextStep();
            }
          }
        },
        loop: true,
      });
    });
  }

  nextStep(conditionKey = "success") {
    if (this.audio) {
      this.audio.stop();
    }
    let nextStep = this.steps.findLast(
      (step) => step.order === this.currentStep.order + 1
    );

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order + 1 &&
            step.conditionKey == conditionKey
        );
      }

      if (nextStep.actionType == "goPage") {
        if (
          this.currentStep.conditionKey &&
          this.currentStep.conditionKey == "failed"
        ) {
          this.scene.start("RestartScene", {
            avatar: this.avatar,
            vocab: this.vocab,
            env: this.env,
            chapter: this.chapter,
            book: this.book,
            books: this.books,
            chapters: this.chapters,
            steps: this.steps,
            currentStep: nextStep,
          });
        } else {
          this.scene.start("StoryScene", {
            avatar: this.avatar,
            vocab: this.vocab,
            env: this.env,
            chapter: this.chapter,
            book: this.book,
            books: this.books,
            chapters: this.chapters,
            steps: this.steps,
            currentStep: nextStep,
          });
        }
      }

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "restartChapter")
        this.scene.start("RestartScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });
    } else {
      this.scene.start("ChapterScoreScene", {
        avatar: this.avatar,
        vocab: this.vocab,
        env: this.env,
        chapters: this.chapters,
        chapter: this.chapter,
        book: this.book,
        books: this.books,
      });
    }
  }

  clickClose() {
    if (this.audio) {
      this.audio.stop();
    }
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("StoryScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapters: this.chapters,
      book: this.book,
      books: this.books,
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "StoryScene",
    });
  }

  update() {}
}
