import Phaser from "phaser";
import LoadingScene from "@/games/ABCMapTesting/scenes/LoadingScene";
import MapScene from "@/games/ABCMapTesting/scenes/MapScene";
import QScene from "@/games/ABCMapTesting/scenes/QScene";
import FightScene from "@/games/ABCMapTesting/scenes/FightScene";
import PuzzleScene from "@/games/ABCMapTesting/scenes/PuzzleScene";
import KombatScene from "@/games/ABCMapTesting/scenes/KombatScene";

import Settings from "@/games/ABCMapTesting/scenes/Settings";

import DropShadowPipelinePlugin from "phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [
        {
          key: "rexDropShadowPipeline",
          plugin: DropShadowPipelinePlugin,
          start: true,
        },
      ],
    },
    dom: {
      createContainer: true,
    },
    scene: [LoadingScene, MapScene, Settings, QScene, PuzzleScene, FightScene, KombatScene],
  });
}

export default launch;
export { launch };
