import { Scene } from "phaser";
// import store from "@/store";
// import router from "@/router";
// import i18n from "@/plugins/i18n";
// import _ from "lodash";
// import Phaser from "phaser";

export default class PuzzleScene extends Scene {
  constructor() {
    super({ key: "PuzzleScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.noOfCuts = data.noOfCuts;
    this.fimage = data.fimage
  }

  playerAvatarObject = [];

  create() {
    this.okBtn = this.add.image(380, 750, "imgOkBtn").setScale(0.6).setDepth(9);
    this.okBtnTxt = this.add
      .text(380, 750, "Give up", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 24,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(9);
    this.okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        this.scene.stop("PuzzleScene");
        this.scene.resume("MapScene", {
          isCorrectAnswer: false,
        });
      },
      this
    );

    // this.imgBackground = this.add
    //   .image(0, 0, "imgBackground")
    //   .setDisplaySize(480, 854)
    //   .setOrigin(0);
    this.add.rectangle(240, 427, 480, 854, 0xffffff);
    this.screen2 = this.add
      .rectangle(240, 427, 480, 854, 0xffffff)
      .setDepth(99);

    this.tweens.add({
      targets: this.screen2,
      alpha: 0,
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {},
    });

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // always square like 140x140 and cut 3X3
    const imageWidth = 420;
    const cutCount = this.noOfCuts;
    const pieceWidth = imageWidth / cutCount;
    const paddingAround = (480 - imageWidth) / 2;

    // let images = ["imgDino1A", "imgDino1B", "imgDino1C", "imgDino1D"];
    let background = this.textures.get("imgPuzzBg").getSourceImage();
    let foreground = this.textures
      .get(this.fimage)
      .getSourceImage();

    let mergedImage = this.mergeImages(
      background,
      foreground,
      imageWidth,
      imageWidth
    );

    this.textures.addImage("mergedImage", mergedImage);

    this.boardBoxGroup = this.add.group();

    this.puzzlePieceGroup = this.add.group();

    let swapper = [];

    for (let i = 0; i < cutCount; i++) {
      for (let j = 0; j < cutCount; j++) {
        const boardBox = this.add
          .rectangle(
            pieceWidth * j + paddingAround,
            pieceWidth * i + paddingAround,
            pieceWidth,
            pieceWidth,
            0xcfcfcf
          )
          .setOrigin(0);
        boardBox.setStrokeStyle(2, 0xffffff);
        swapper.push(i * cutCount + j);
        boardBox.position = i * cutCount + j;
        boardBox.positionX = j;
        boardBox.positionY = i;
        this.boardBoxGroup.add(boardBox);
      }
    }

    const swapGap = (imageWidth - pieceWidth) / (cutCount * cutCount - 1);

    for (let i = 0; i < cutCount; i++) {
      for (let j = 0; j < cutCount; j++) {
        const puzzlePiece = this.add.image(0, 0, "mergedImage").setOrigin(0);
        puzzlePiece.setCrop(
          j * pieceWidth,
          i * pieceWidth,
          pieceWidth,
          pieceWidth
        );
        puzzlePiece.setInteractive();
        this.input.setDraggable(puzzlePiece);
        puzzlePiece.input.hitArea.setTo(
          j * pieceWidth,
          i * pieceWidth,
          pieceWidth,
          pieceWidth
        );
        puzzlePiece.swapPosition = swapper.splice(
          Math.floor(Math.random() * swapper.length),
          1
        )[0];
        puzzlePiece.setDepth(1 + puzzlePiece.swapPosition);
        puzzlePiece.position = i * cutCount + j;
        puzzlePiece.puzzleId = puzzlePiece.position;
        puzzlePiece.positionX = j;
        puzzlePiece.positionY = i;
        puzzlePiece.previousBoardBoxPosition = -1;

        puzzlePiece.y = 500 - puzzlePiece.positionY * pieceWidth;
        puzzlePiece.x =
          puzzlePiece.swapPosition * swapGap -
          puzzlePiece.positionX * pieceWidth +
          paddingAround;

        this.plugins.get("rexDropShadowPipeline").add(puzzlePiece, {
          distance: 3,
          angle: 180,
          shadowColor: 0x000000,
          alpha: 0.5,
          blur: 2,
          pixelWidth: 1,
          pixelHeight: 0,
        });
        puzzlePiece.setTint(0xe8e8e8);
        this.puzzlePieceGroup.add(puzzlePiece);
      }
    }

    this.input.on("drag", (pointer, puzzlePiece) => {
      puzzlePiece.x =
        pointer.x - puzzlePiece.positionX * pieceWidth - pieceWidth / 2;
      puzzlePiece.y =
        pointer.y - puzzlePiece.positionY * pieceWidth - pieceWidth / 2;
    });

    this.input.on("dragstart", (pointer, puzzlePiece) => {
      if (puzzlePiece.scaleX != 1) {
        puzzlePiece.x =
          pointer.x - puzzlePiece.positionX * pieceWidth - pieceWidth / 2;
        puzzlePiece.y =
          pointer.y - puzzlePiece.positionY * pieceWidth - pieceWidth / 2;
      }
      this.boardBoxGroup.getChildren().forEach((boardBox) => {
        if (
          pointer.x > boardBox.x &&
          pointer.x < boardBox.x + pieceWidth &&
          pointer.y > boardBox.y &&
          pointer.y < boardBox.y + pieceWidth &&
          boardBox.isPuzzleIn
        ) {
          boardBox.isPuzzleIn = false;
        }
      });
      puzzlePiece.clearTint();
      puzzlePiece.setDepth(99);
    });

    this.input.on("dragend", (pointer, puzzlePiece) => {
      puzzlePiece.placedOnBoard = false;
      this.boardBoxGroup.getChildren().forEach((boardBox) => {
        if (
          pointer.x > boardBox.x &&
          pointer.x < boardBox.x + pieceWidth &&
          pointer.y > boardBox.y &&
          pointer.y < boardBox.y + pieceWidth
        ) {
          puzzlePiece.x =
            boardBox.positionX * pieceWidth -
            puzzlePiece.positionX * pieceWidth +
            paddingAround;
          puzzlePiece.y =
            boardBox.positionY * pieceWidth -
            puzzlePiece.positionY * pieceWidth +
            paddingAround;
          puzzlePiece.placedOnBoard = true;
          puzzlePiece.setDepth(cutCount * cutCount + 1 - puzzlePiece.position);

          if (!boardBox.isPuzzleIn) {
            boardBox.isPuzzleIn = true;
            boardBox.puzzleId = puzzlePiece.puzzleId;
            puzzlePiece.previousBoardBoxPosition = boardBox.position;
          } else {
            let oldPuzzlePiece = this.puzzlePieceGroup
              .getChildren()
              .find(
                (puzzlePieceX) => puzzlePieceX.puzzleId == boardBox.puzzleId
              );

            let oldBoardBox = this.boardBoxGroup
              .getChildren()
              .find((boardBoxX) => boardBoxX.puzzleId == puzzlePiece.puzzleId);

            if (puzzlePiece.previousBoardBoxPosition == -1) {
              oldPuzzlePiece.previousBoardBoxPosition = -1;
              oldPuzzlePiece.placedOnBoard = false;
              oldPuzzlePiece.placedCorrectly = false;
              oldPuzzlePiece.y = 500 - oldPuzzlePiece.positionY * pieceWidth;
              oldPuzzlePiece.x =
                oldPuzzlePiece.swapPosition * swapGap -
                oldPuzzlePiece.positionX * pieceWidth +
                paddingAround;
              oldPuzzlePiece.setDepth(1 + oldPuzzlePiece.swapPosition);
              oldPuzzlePiece.setTint(0xe8e8e8);
            } else {
              oldBoardBox.isPuzzleIn = true;
              oldBoardBox.puzzleId = oldPuzzlePiece.puzzleId;
              oldPuzzlePiece.previousBoardBoxPosition = oldBoardBox.position;
              oldPuzzlePiece.x =
                oldBoardBox.positionX * pieceWidth -
                oldPuzzlePiece.positionX * pieceWidth +
                paddingAround;
              oldPuzzlePiece.y =
                oldBoardBox.positionY * pieceWidth -
                oldPuzzlePiece.positionY * pieceWidth +
                paddingAround;
              oldPuzzlePiece.placedOnBoard = true;
              oldPuzzlePiece.setDepth(
                cutCount * cutCount + 1 - oldPuzzlePiece.position
              );
              if (oldBoardBox.position == oldPuzzlePiece.position) {
                oldPuzzlePiece.placedCorrectly = true;
              } else {
                oldPuzzlePiece.placedCorrectly = false;
              }
            }
          }

          boardBox.puzzleId = puzzlePiece.puzzleId;
          boardBox.isPuzzleIn = true;
          puzzlePiece.previousBoardBoxPosition = boardBox.position;

          if (boardBox.position == puzzlePiece.position) {
            puzzlePiece.placedCorrectly = true;
            let count = 0;
            this.puzzlePieceGroup.getChildren().forEach((boardBox) => {
              if (boardBox.placedCorrectly) {
                count++;
              }
            });
            if (count == cutCount * cutCount) {
              this.puzzlePieceGroup.getChildren().forEach((boardBox) => {
                boardBox.removeInteractive();
              });
              this.add
                .text(240, this.cameras.main.scrollY + 500, "Nice Work!", {
                  color: "#fff",
                  fontSize: "50px",
                  fontFamily: "Sassoon, sans-serif",
                  stroke: "#000000",
                  strokeThickness: 5,
                })
                .setDepth(8)
                .setShadow(3, 3, "#000", 0, true, true)
                .setOrigin(0.5);
              this.time.delayedCall(1000, () => {
                this.scene.stop("PuzzleScene");
                this.scene.resume("MapScene", {
                  isCorrectAnswer: true,
                });
              });
            }
          } else {
            puzzlePiece.placedCorrectly = false;
          }
        }
      });

      if (!puzzlePiece.placedOnBoard) {
        puzzlePiece.placedCorrectly = false;
        puzzlePiece.y = 500 - puzzlePiece.positionY * pieceWidth;
        puzzlePiece.x =
          puzzlePiece.swapPosition * swapGap -
          puzzlePiece.positionX * pieceWidth +
          paddingAround;
        puzzlePiece.setDepth(1 + puzzlePiece.swapPosition);
        puzzlePiece.setTint(0xe8e8e8);
      }
    });
  }

  createBox() {}

  mergeImages(background, foreground, width, height) {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = background.width;
    canvas.height = background.height;
    ctx.drawImage(background, 0, 0, width, height);
    ctx.drawImage(foreground, 0, 0, width, height);
    return canvas;
  }

  update() {
    //
  }
}
