import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class StoryLoadingScene extends Scene {
  constructor() {
    super({ key: "StoryLoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.chapter = data.chapter;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
    this.steps = data.steps;
  }

  preload() {
    // Create progress bar elements
    this.createLoadingScreen();

    // Load assets
    this.loadAssets();

    // Handle loading events
    this.setupLoadingEvents();
  }

  createLoadingScreen() {
    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    this.loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    this.loadingText.setOrigin(0.5, 0.5);

    this.percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    this.percentText.setOrigin(0.5, 0.5);

    this.assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    this.assetText.setOrigin(0.5, 0.5);

    this.progressBar = progressBar;
    this.progressBox = progressBox;
  }

  loadAssets() {
    // Load stories audio
    for (let step of this.steps) {
      if (step.actionType == "goPage") {
        this.load.audio("sfx" + step.Page.key, step.Page.voiceover.url);
      }
    }

    // Load stories images
    for (let step of this.steps) {
      if (step.actionType == "goPage") {
        this.load.image("img" + step.Page.key, step.Page.image.url);
      }
    }
  }

  setupLoadingEvents() {
    this.load.on("progress", this.updateProgress, this);
    this.load.on("fileprogress", this.updateFileProgress, this);
    this.load.on("complete", this.completeLoading, this);
  }

  updateProgress(value) {
    this.percentText.setText(parseInt(value * 100) + "%");
    this.progressBar.clear();
    this.progressBar.fillStyle(0x000000, 1);
    this.progressBar.fillRect(110, 280, 260 * value, 30);
  }

  updateFileProgress(file) {
    this.assetText.setText("Loading asset: " + file.key);
  }

  completeLoading() {
    this.progressBar.destroy();
    this.progressBox.destroy();
    this.loadingText.destroy();
    this.percentText.destroy();
    this.assetText.destroy();
  }

  create() {
    this.registry.destroy(); // Clear the registry
    this.events.off(); // Remove all active events
    this.scene.stop("StoryLoadingScene"); // Stop this scene
    this.nextStep();
  }

  nextStep(conditionKey = "success") {
    let nextStep = this.steps.find((step) => step.order === 1);

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order + 1 &&
            step.conditionKey == conditionKey
        );
      }

      console.log(nextStep);

      if (nextStep.actionType == "goPage")
        this.scene.start("StoryScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "restartChapter")
        this.scene.start("RestartScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });
    } else {
      this.scene.start("ChapterScoreScene", {
        avatar: this.avatar,
        vocab: this.vocab,
        env: this.env,
        chapters: this.chapters,
        chapter: this.chapter,
        book: this.book,
        books: this.books,
      });
    }
  }
}
