import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";

export default class MapScene extends Scene {
  constructor() {
    super({ key: "MapScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.chapter = data.chapter;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.add
      .text(240, 250, "Map Scene", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 300, "End map with condition", {
        fontFamily: "WarungKopi, sans-serif",
        fontSize: 24,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);

    this.add
      .image(240, 370, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.nextStep("success"), this);

    this.add
      .text(235, 370, "Success", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 440, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.nextStep("failed"), this);

    this.add
      .text(235, 440, "Failed", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);
  }

  nextStep(conditionKey = "success") {
    let nextStep = this.steps.findLast(
      (step) => step.order === this.currentStep.order + 1
    );

    if (nextStep) {
      if (nextStep.conditionKey) {
        nextStep = this.steps.findLast(
          (step) =>
            step.order === this.currentStep.order + 1 &&
            step.conditionKey == conditionKey
        );
      }

      console.log(nextStep);

      if (nextStep.actionType == "goPage")
        this.scene.start("StoryScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goMap")
        this.scene.start("MapScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goGame")
        this.scene.start("GameScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "goItem")
        this.scene.start("ItemScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });

      if (nextStep.actionType == "restartChapter")
        this.scene.start("RestartScene", {
          avatar: this.avatar,
          vocab: this.vocab,
          env: this.env,
          chapter: this.chapter,
          book: this.book,
          books: this.books,
          chapters: this.chapters,
          steps: this.steps,
          currentStep: nextStep,
        });
    } else {
      this.scene.start("ChapterScoreScene", {
        avatar: this.avatar,
        vocab: this.vocab,
        env: this.env,
        chapters: this.chapters,
        chapter: this.chapter,
        book: this.book,
        books: this.books,
      });
    }
  }

  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("MapScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapters: this.chapters,
      book: this.book,
      books: this.books,
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "MapScene",
    });
  }
  update() {}
}
