import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";
import axios from "axios";

export default class ChapterIntroScene extends Scene {
  constructor() {
    super({ key: "ChapterIntroScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.chapter = data.chapter;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
  }

  preload() {
    // this.load.scenePlugin({
    //   key: "rexuiplugin",
    //   url: RexUIPlugin,
    //   sceneKey: "rexUI",
    // });

    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");
    if (locale == "/") locale = "en";

    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/${locale}/game/abc/chapter/read`,
        { chapterKey: this.chapter.key },
        { headers }
      )
      .then((resp) => {
        this.steps = resp.data.Book;
        console.log(this.steps);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");
    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);
    this.deckImgTxt = this.add
      .text(240, 280, this.chapter.key, {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);
    this.scoreText = this.add
      .text(0, 320, this.chapter.name, {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 32,
        color: "#492a12",
      })
      .setOrigin(0);
    let textResetWidth = this.scoreText.width;
    this.scoreText.setX(240 - textResetWidth / 2);

    if (this.chapter.id != 1) {
      for (let i = 0; i < 3; i++) {
        this.add
          .sprite(180 + i * 60, 400, "sptStar")
          .setScale(0.6)
          .setFrame(
            this.chapter.PlayerChapter.length > 0 &&
              this.chapter.PlayerChapter[0].totalStar > i
              ? 1
              : 0
          );
      }
    }

    this.playBtn = this.add
      .image(240, 480, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.goStory, this);

    this.okBtnTxt = this.add
      .text(240, 480, "PLAY", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 50,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(0.6);

    this.tweens.add({
      targets: [this.playBtn, this.okBtnTxt],
      scaleX: 0.65,
      scaleY: 0.65,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });
  }
  goStory() {
    this.scene.stop("ChapterIntroScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("StoryLoadingScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: this.chapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
      steps: this.steps,
    });
  }
  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("ChapterIntroScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "ChapterIntroScene",
    });
  }
  update() {}
}
