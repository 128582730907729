<script>
import { mapState } from "vuex";
const ABC = require("@/games/ABCMapTesting/game");
export default {
  components: {
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    event: (state) => state.event.data,
    skin: (state) => state.skin.data,
    hatData: (state) => state.apparel.data,
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    series: (state) => state.series.data,
  }),
  props: [
    //
  ],
  data: () => ({
    isMonsterChase: true,
    cardDetails: 2,
    timeChallenge: 1,
    isBackward: true,
    isHorizontal: true,
    isLife: true,
    isSteps: false,
    isTime: false,
    lifeCount: 3,
    stepsCount: 10,
    timeCount: 5,
    startTime: null,
    endTime: null,
    env: process.env,
    noOfRounds: 3,
    containerId: "game-container",
    gameInstance: null,
    bossRound: -1, // make it random
    master: 0,
    music: 0,
    sfx: 0,
    showDifficulty: true,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    vocabApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    questions: [],
    avatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    friendName: null,
    friendAvatarItem: {
      shadow: null,
      back: null,
      right: null,
      body: null,
      outline: null,
      cheek: null,
      mouth: null,
      eye: null,
      hair: null,
      left: null,
      hat: null,
      color: null,
    },
    avatarData: null,
    avatarBack: null,
    avatarRight: null,
    avatarLeft: null,
    avatarOutline: null,
    avatarMouth: null,
    avatarBody: null,
    avatarShadow: null,
    avatarEye: null,
    avatarHair: null,
    avatarCheek: null,
    avatarColor: null,
    avatarHat: null,
    mouth: [],
    body: [],
    outline: [],
    shadow: [],
    eye: [],
    hair: [],
    cheek: [],
    hat: [],
    left: [],
    right: [],
    back: [],
  }),
  created() {
    this.vocabApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/vocab/player/count";
    this.vocabApi.callbackReset = () => {
      this.vocabApi.isLoading = true;
      this.vocabApi.isError = false;
      this.vocabApi.error = null;
    };
    this.vocabApi.callbackError = (e) => {
      this.vocabApi.isLoading = false;
      this.vocabApi.isError = true;
      this.vocabApi.error = e;
    };
    this.vocabApi.callbackSuccess = (resp) => {
      console.log(resp);
      this.vocabApi.isLoading = false;
      this.vocabCount = resp.count;
      // this.startGame()
    };
  },
  mounted() {
    this.startTime = new Date();
    this.avatarData = JSON.parse(this.auth.Player.avatar);
    this.selectSkin(this.avatarData);
    this.$api.fetch(this.vocabApi);
  },
  beforeDestroy() {
    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.gameInstance) {
      this.gameInstance.destroy(true);
      this.gameInstance = null;
    }
  },
  methods: {
    clickStartGame() {
      this.showDifficulty = false;
      this.startGame();
    },
    startGame() {
      this.gameInstance = ABC.launch(this.containerId);
      this.gameInstance.scene.start("LoadingScene", {
        isBackward: this.isBackward,
        isHorizontal: this.isHorizontal,
        isLife: this.isLife,
        isSteps: this.isSteps,
        isTime: this.isTime,
        lifeCount: this.lifeCount,
        stepsCount: this.stepsCount,
        timeCount: this.timeCount,
        timeChallenge: this.timeChallenge,
        cardDetails: this.cardDetails,
        isMonsterChase: this.isMonsterChase,
        avatar: this.avatarItem,
        vocab: this.vocabCount,
        env: this.env,
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
    selectSkin(avatarData, isFriend = false) {
      this.body = this.skin.body;
      this.outline = this.skin.outline;
      this.mouth = this.skin.mouth;
      this.shadow = this.skin.shadow;
      this.eye = this.skin.eye;
      this.hair = this.skin.hair;
      this.cheek = this.skin.cheek;
      var outline = avatarData.body;
      var mouth = avatarData.mouth;
      var hat = avatarData.hat;
      var left = avatarData.left;
      var right = avatarData.right;
      var back = avatarData.back;
      var avatarOutline = this.outline.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarOutline = JSON.parse(avatarOutline[0].images).F0.url;
      var avatarBody = this.body.filter(function (entry) {
        return entry.character == outline;
      });
      this.avatarBody = JSON.parse(avatarBody[0].images).F0.url;
      var avatarMouth = this.mouth.filter(function (entry) {
        return entry.character == mouth;
      });
      this.avatarMouth = JSON.parse(avatarMouth[0].images).F0.url;

      if (hat) {
        var avatarHat = this.hatData.filter(function (entry) {
          return entry.key == hat;
        });
        this.avatarHat = JSON.parse(avatarHat[0].images).apparel.url;
      }
      if (left) {
        var avatarLeft = this.hatData.filter(function (entry) {
          return entry.key == left;
        });
        this.avatarLeft = JSON.parse(avatarLeft[0].images).apparel.url;
      }
      if (right) {
        var avatarRight = this.hatData.filter(function (entry) {
          return entry.key == right;
        });
        this.avatarRight = JSON.parse(avatarRight[0].images).apparel.url;
      }
      if (back) {
        var avatarBack = this.hatData.filter(function (entry) {
          return entry.key == back;
        });
        this.avatarBack = JSON.parse(avatarBack[0].images).apparel.url;
      }

      this.avatarShadow = JSON.parse(this.shadow[0].images).F0.url;
      this.avatarEye = JSON.parse(this.eye[0].images).F0.url;
      this.avatarHair = JSON.parse(this.hair[0].images).F0.url;
      this.avatarCheek = JSON.parse(this.cheek[0].images).F0.url;
      this.avatarColor = avatarData.pColor;
      if (isFriend) {
        this.friendAvatarItem.back = this.avatarBack;
        this.friendAvatarItem.body = this.avatarBody;
        this.friendAvatarItem.eye = this.avatarEye;
        this.friendAvatarItem.hat = this.avatarHat;
        this.friendAvatarItem.left = this.avatarLeft;
        this.friendAvatarItem.mouth = this.avatarMouth;
        this.friendAvatarItem.right = this.avatarRight;
        this.friendAvatarItem.shadow = this.avatarShadow;
        this.friendAvatarItem.outline = this.avatarOutline;
        this.friendAvatarItem.cheek = this.avatarCheek;
        this.friendAvatarItem.hair = this.avatarHair;
        this.friendAvatarItem.color = this.avatarColor;
        this.startGame();
      } else {
        this.avatarItem.back = this.avatarBack;
        this.avatarItem.body = this.avatarBody;
        this.avatarItem.eye = this.avatarEye;
        this.avatarItem.hat = this.avatarHat;
        this.avatarItem.left = this.avatarLeft;
        this.avatarItem.mouth = this.avatarMouth;
        this.avatarItem.right = this.avatarRight;
        this.avatarItem.shadow = this.avatarShadow;
        this.avatarItem.outline = this.avatarOutline;
        this.avatarItem.cheek = this.avatarCheek;
        this.avatarItem.hair = this.avatarHair;
        this.avatarItem.color = this.avatarColor;
      }
    },
  },
};
</script>

<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="game-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <ApiErrorDialog v-if="vocabApi.isError" :api="vocabApi" />
          <!-- EOC -->
          <div
            class="d-flex flex-column justify-center align-center dif"
            v-if="showDifficulty"
          >
            <div class="px-6" style="width: 300px">
              <!-- Movements
              <v-checkbox
                v-model="isBackward"
                label="Backward"
                color="ash"
                dense
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="isHorizontal"
                label="Horizontal"
                color="ash"
                dense
              ></v-checkbox>

              Constraints
              <div class="d-flex justify-space-between my-1">
                <v-checkbox
                  v-model="isLife"
                  label="Life"
                  color="ash"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  v-if="isLife"
                  v-model="lifeCount"
                  type="number"
                  color="ash"
                  :step="1"
                  :min="1"
                  :max="3"
                  dense
                  solo
                  hide-details
                  style="max-width: 100px"
                ></v-text-field>
              </div>
              <div class="d-flex justify-space-between my-1">
                <v-checkbox
                  v-model="isSteps"
                  label="Steps"
                  color="ash"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  v-if="isSteps"
                  v-model="stepsCount"
                  type="number"
                  color="ash"
                  :step="1"
                  :min="1"
                  :max="3"
                  dense
                  solo
                  hide-details
                  style="max-width: 100px"
                ></v-text-field>
              </div>
              <div class="d-flex justify-space-between my-1">
                <v-checkbox
                  v-model="isTime"
                  label="Time"
                  color="ash"
                  dense
                  hide-details
                ></v-checkbox>
                <v-text-field
                  v-if="isTime"
                  v-model="timeCount"
                  type="number"
                  color="ash"
                  :step="1"
                  :min="1"
                  :max="3"
                  dense
                  solo
                  hide-details
                  style="max-width: 100px"
                ></v-text-field>
              </div>

              <v-radio-group v-model="timeChallenge" dense v-if="isTime">
                Time Challenge
                <v-radio
                  color="ash"
                  label="Natural Disasters"
                  :value="1"
                  class="mt-2"
                ></v-radio>
                <v-radio
                  color="ash"
                  label="Monstor Attack"
                  :value="2"
                ></v-radio>
              </v-radio-group>

              <v-radio-group v-model="cardDetails" dense>
                Card Details
                <v-radio
                  color="ash"
                  label="Hide all"
                  :value="1"
                  class="mt-2"
                ></v-radio>
                <v-radio color="ash" label="Hide random" :value="2"></v-radio>
                <v-radio color="ash" label="Hide none" :value="3"></v-radio>
              </v-radio-group>

              <div v-if="!isTime">
                Challenges
                <v-checkbox
                  v-model="isMonsterChase"
                  label="Monster chase"
                  color="ash"
                  dense
                  hide-details
                ></v-checkbox>
              </div> -->

              <div class="mb-6 pb-6 mt-4">
                <v-btn @click="clickStartGame()" block>Start game</v-btn>
              </div>
            </div>
          </div>

          <div
            :id="containerId"
            style="max-width: 480px; padding: 0; max-height: 100vh !important"
          />
          <div
            v-if="!gameInstance && !showDifficulty"
            style="position: absolute"
            class="d-flex align-center justify-center flex-column"
          >
            <v-progress-linear
              color="brown darken-1"
              indeterminate
              height="10"
              style="max-width: 200px"
              class="my-5"
            ></v-progress-linear>
            <span class="brown--text py-2">
              {{ $t("string.game_loading_msg") }}</span
            >

            <v-btn
              depressed
              color="brown lighten-3"
              class="brown--text text--darken-3"
              @click="
                () => {
                  $router.go($router.currentRoute);
                }
              "
            >
              ↻ {{ $t("action.retry") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>

<style>
.game-body {
  max-width: 480px;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: #d684314d;
  position: relative;
}
.dif {
  /* padding-top: 500px; */
  position: absolute;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  /* overflow: hidden; */
}
</style>