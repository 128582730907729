import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";

export default class ChapterScoreScene extends Scene {
  constructor() {
    super({ key: "ChapterScoreScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
    this.chapter = data.chapter;
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.add
      .text(240, 250, "Chapter Score Scene", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .text(240, 300, "End Chapter with", {
        fontFamily: "WarungKopi, sans-serif",
        fontSize: 24,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);

    this.add
      .image(240, 370, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.goChapter(0), this);

    this.add
      .text(235, 370, "0 star", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 440, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.goChapter(1), this);

    this.add
      .text(235, 440, "1 star", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 510, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.goChapter(2), this);

    this.add
      .text(235, 510, "2 star", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 580, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.goChapter(3), this);

    this.add
      .text(235, 580, "3 star", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);
  }

  goChapter(stars) {
    let currentChapterIndex = this.chapters.findIndex(
      (chapter) => chapter.key === this.chapter.key
    );
    this.chapters[currentChapterIndex].PlayerChapter = [
      { totalStar: stars, isPlayed: true },
    ];

    let totalStar = 0;
    this.chapters.forEach((chapter, i) => {
      if (chapter.PlayerChapter.length > 0 && i > 0) {
        totalStar += chapter.PlayerChapter[0].totalStar;
      }
    });
    this.book.PlayerBook = [{ totalStar: totalStar }];

    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapters: this.chapters,
      book: this.book,
      books: this.books,
    });
  }

  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("ChapterScoreScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapters: this.chapters,
      book: this.book,
      books: this.books,
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "ChapterScoreScene",
    });
  }
  update() {}
}
