import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
import axios from "axios";
// import router from "@/router";
// import _ from "lodash";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class BookIntroScene extends Scene {
  constructor() {
    super({ key: "BookIntroScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.book = data.book;
    this.books = data.books;
  }

  COLOR_PRIMARY = 0x4f4f4f;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });

    if (!this.chapters) {
      let token = store.state.auth.data.Session.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");
      if (locale == "/") locale = "en";

      axios
        .post(
          `${this.env.VUE_APP_URL_API_GAME}/api/v1/${locale}/game/abc/chapter/browse`,
          { bookKey: this.book.key },
          { headers }
        )
        .then((resp) => {
          this.chapters = resp.data.Chapter;
          console.log(this.chapters);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  create() {
    // this.add.rectangle(240, 427, 480, 854, 0xffffff);
    this.add.rectangle(0, 0, 480, 80, 0x8f8f8f).setOrigin(0);

    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    const scrollablePanel = this.rexUI.add
      .scrollablePanel({
        x: 240,
        y: 467,
        width: 480,
        height: 770,

        scrollMode: 0, // 0: vertical, 1: horizontal

        panel: {
          child: this.createGrid(),
          mask: { padding: 1 },
        },

        slider: {
          track: this.rexUI.add.roundRectangle(
            0,
            0,
            20,
            10,
            10,
            this.COLOR_DARK
          ),
          thumb: this.add.image(0, 0, "imgThumb"),
          // thumb: this.rexUI.add.roundRectangle(
          //   0,
          //   0,
          //   0,
          //   50,
          //   13,
          //   this.COLOR_LIGHT
          // ),
        },

        space: {
          left: 30,
          right: 30,
          top: 0,
          bottom: 0,

          panel: {
            top: 30,
            bottom: 30,
            left: 0,
            right: 20,
          },
          slider: {
            top: 20,
            bottom: 20,
            left: 0,
            right: 0,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },

        // Other configurations...
      })
      .layout();

    scrollablePanel
      .setChildrenInteractive({ useHandCursor: true, cursor: "pointer" })
      .on("child.click", (child) => {
        child
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", this.readBook, this);
        if (child.isReadButton) {
          this.readBook();
        }
      });
  }

  createGrid() {
    var sizer = this.rexUI.add.sizer({
      orientation: "y",
      space: { item: 20, bottom: 50 },
    });

    // sizer.add(
    //   this.add
    //     .text(240, 110, this.book.name, {
    //       fontFamily: "MouseMemoirs, sans-serif",
    //       fontSize: 32,
    //       color: "#000000",
    //     })
    //     .setOrigin(0.5)
    // );

    sizer.add(
      this.add.image(240, 400, this.book.key).setDisplaySize(350, 500),
      {
        key: undefined,
        align: "center",
        offsetX: 0,
        offsetY: 0,
        padding: { left: 0, right: 0, top: 10, bottom: 0 },
        expand: false,
        minWidth: undefined,
        minHeight: undefined,
        aspectRatio: 0,
      }
    );

    if (this.book.PlayerBook.length > 0) {
      const containerBar = this.rexUI.add.overlapSizer();
      containerBar.add(
        this.rexUI.add.roundRectangle(240, 670, 350, 28, 14, 0x4f4f4f)
      );
      if (this.book.PlayerBook[0].totalStar > 0) {
        containerBar.add(
          this.rexUI.add.roundRectangle(
            94,
            670,
            342 *
              (this.book.PlayerBook[0].totalStar /
                ((this.book._count.Chapter - 1) * 3)),
            12,
            10,
            0x00ffff
          ),
          {
            align: "left",
            offsetX: 4,
            offsetY: 0,
            expand: false,
          }
        );
      }
      sizer.add(containerBar);

      const containerCaption = this.rexUI.add.sizer({
        orientation: "x",
      });

      containerCaption.add(
        this.add.image(0, 0, "imgStar").setScale(0.4).setDepth(5)
      );
      containerCaption.add(
        this.add.text(
          0,
          0,
          this.book.PlayerBook[0].totalStar +
            "/" +
            (this.book._count.Chapter - 1) * 3,
          {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 28,
            color: "#000",
          }
        ),
        0,
        "center",
        { left: 20 }
      );

      sizer.add(containerCaption, 0, "center", { bottom: 5 });

      const containerButton = this.rexUI.add.overlapSizer();

      containerButton.add(
        this.add
          .image(240, 780, "imgOkBtn")
          .setScale(0.6)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", this.readBook, this)
      );

      containerButton.add(
        this.add
          .text(240, 780, "Read", {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 50,
            color: "#000",
          })
          .setScale(0.6),
        {
          expand: false,
        }
      );

      containerButton.isReadButton = true;
      sizer.add(containerButton);

      this.tweens.add({
        targets: [containerButton],
        scaleX: 1.1,
        scaleY: 1.1,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });
    } else {
      sizer.add(
        this.add
          .text(240, 710, this.book.currencyType + " " + this.book.price, {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 28,
            color: "#000",
          })
          .setOrigin(0.5)
          .setDepth(5)
      );

      const containerButton = this.rexUI.add.overlapSizer();

      containerButton.add(
        this.add
          .image(240, 780, "imgOkBtn")
          .setScale(0.6)
          .setInteractive({ cursor: "pointer" })
          .on("pointerdown", this.readBook, this)
      );

      containerButton.add(
        this.add
          .text(240, 780, "Purchase", {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 50,
            color: "#000",
          })
          .setOrigin(0.5)
          .setScale(0.6),
        {
          expand: false,
        }
      );

      sizer.add(containerButton);

      this.tweens.add({
        targets: [containerButton],
        scaleX: 1.1,
        scaleY: 1.1,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });
    }

    sizer.add(
      this.add
        .text(240, 110, this.book.name, {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 32,
          color: "#000000",
        })
        .setOrigin(0.5),
      {
        // key: undefined,
        // align: 'center',
        // offsetX: 0,
        // offsetY: 0,
        padding: { left: 0, right: 0, top: 50, bottom: 0 },
        // expand: true,        // expand: {width, height}
        // minWidth: undefined,
        // minHeight: undefined,
        // aspectRatio: 0,
      }
    );

    sizer.add(
      this.add
        .text(
          240,
          550,
          this.book.summary.replace(/，/g, "， ").replace(/。/g, "。 "),
          {
            fontFamily: "WarungKopi, sans-serif",
            fontSize: 21,
            color: "#000",
            wordWrap: { width: 350 },
            align: "center",
          }
        )
        .setLineSpacing(10)
      // {
      //   // key: undefined,
      //   // align: 'center',
      //   // offsetX: 0,
      //   // offsetY: 0,
      //   padding: { left: 0, right: 0, top: 50, bottom: 0 },
      //   // expand: true,        // expand: {width, height}
      //   // minWidth: undefined,
      //   // minHeight: undefined,
      //   // aspectRatio: 0,
      // }
    );

    return sizer;
  }

  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("ChaptersScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("BooksScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      books: this.books,
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BookIntroScene",
    });
  }
  readBook() {
    this.scene.stop("BookIntroScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }
  update() {}
}
