import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class ChaptersScene extends Scene {
  constructor() {
    super({ key: "ChaptersScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
  }

  COLOR_PRIMARY = 0xb7b7b7;
  COLOR_LIGHT = 0x4f4f4f;
  COLOR_DARK = 0x8f8f8f;

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.add.rectangle(0, 0, 480, 200, 0x8f8f8f).setOrigin(0);

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setDepth(5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.titleText = this.add
      .text(40, 100, this.book.name, {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setDepth(5);

    this.rexUI.add
      .roundRectangle(40, 170, 300, 28, 14, 0x4f4f4f)
      .setOrigin(0, 0.5);
    if (this.book.PlayerBook[0].totalStar > 0) {
      this.rexUI.add
        .roundRectangle(
          44,
          170,
          292 *
            (this.book.PlayerBook[0].totalStar /
              ((this.book._count.Chapter - 1) * 3)),
          12,
          10,
          0x00ffff
        )
        .setOrigin(0, 0.5);
    }

    this.add.image(420, 150, "imgBook").setScale(0.4).setDepth(5);

    this.add.image(280, 140, "imgStar").setScale(0.25).setDepth(5);
    this.add
      .text(
        300,
        140,
        this.book.PlayerBook[0].totalStar +
          "/" +
          (this.book._count.Chapter - 1) * 3,
        {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 20,
          color: "#000",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(5);

    const scrollablePanel = this.rexUI.add
      .scrollablePanel({
        x: 240,
        y: 527,
        width: 480,
        height: 650,

        scrollMode: 0, // 0: vertical, 1: horizontal

        panel: {
          child: this.createChapters(),
          mask: { padding: 1 },
        },

        slider: {
          track: this.rexUI.add.roundRectangle(
            0,
            0,
            20,
            10,
            10,
            this.COLOR_DARK
          ),
          thumb: this.add.image(0, 0, "imgThumb"),
        },

        space: {
          left: 30,
          right: 30,
          top: 0,
          bottom: 0,

          panel: {
            top: 20,
            bottom: 20,
            left: 0,
            right: 20,
          },
          slider: {
            top: 20,
            bottom: 20,
            left: 0,
            right: 0,
          },
        },

        mouseWheelScroller: {
          focus: true,
          speed: 0.1,
        },

        // Other configurations...
      })
      .layout();

    scrollablePanel
      .setChildrenInteractive({ useHandCursor: true, cursor: "pointer" })
      .on("child.click", (child) => {
        console.log(child);
        if (child.isReadable) {
          this.goStory(child.chapter);
        }
        if (child.canUnlock) {
          this.unlockChapter(child.chapter);
        }
      });

    if (this.chapters.length * 150 < 650) {
      // scrollablePanel.setSliderEnable(false);
      // scrollablePanel.setScrollerEnable(false);
      // scrollablePanel.setMouseWheelScrollerEnable(false);
      scrollablePanel.on("scroll", (panel) => {
        panel.scrollToTop();
      });
    }
  }

  createChapters() {
    let bottom =
      this.chapters.length * 150 < 650 ? 650 - this.chapters.length * 150 : 0;

    var sizer = this.rexUI.add.sizer({
      orientation: "y",
      space: { bottom: 50 + bottom, item: 5 },
    });

    this.chapters.forEach((chapter, i) => {
      const container = this.rexUI.add.overlapSizer({
        space: { left: 0, right: 0, top: 10, bottom: 0 },
      });
      container.chapter = chapter;

      container.add(
        this.add.rectangle(0, 0, 360, 130, this.COLOR_PRIMARY).setOrigin(0)
      );
      container.add(
        this.add.text(0, 0, chapter.key, {
          fontFamily: "MouseMemoirs, sans-serif",
          fontSize: 28,
          color: "#000",
        }),
        {
          key: undefined,
          align: "left",
          padding: { left: 60, right: 0, top: -80, bottom: 0 },
          expand: false,
        }
      );
      container.add(
        this.add.text(
          0,
          0,
          chapter.PlayerChapter.length > 0 || i == 0 ? chapter.name : "???",
          {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 20,
            color: "#000",
          }
        ),
        {
          key: undefined,
          align: "left",
          padding: { left: 60, right: 0, top: -20, bottom: 0 },
          expand: false,
        }
      );
      container.add(
        this.add
          .sprite(0, 0, "sptBookMark")
          .setScale(0.25)
          .setFrame(
            chapter.PlayerChapter.length > 0 &&
              chapter.PlayerChapter[0].isPlayed
              ? 2
              : i == 0 || chapter.PlayerChapter.length > 0
              ? 1
              : 0
          ),
        {
          key: undefined,
          align: "left",
          padding: { left: 10, right: 0, top: -79, bottom: 0 },
          expand: false,
        }
      );

      if (chapter.PlayerChapter.length > 0 && i != 0) {
        var containerStars = this.rexUI.add.sizer({
          orientation: "x",
          space: { item: 5, left: 260 },
        });

        containerStars.add(
          this.add
            .sprite(0, 0, "sptStar")
            .setScale(0.25)
            .setDepth(5)
            .setFrame(chapter.PlayerChapter[0].totalStar > 0 ? 1 : 0)
        );
        containerStars.add(
          this.add
            .sprite(0, 0, "sptStar")
            .setScale(0.25)
            .setDepth(5)
            .setFrame(chapter.PlayerChapter[0].totalStar > 1 ? 1 : 0)
        );
        containerStars.add(
          this.add
            .sprite(0, 0, "sptStar")
            .setScale(0.25)
            .setDepth(5)
            .setFrame(chapter.PlayerChapter[0].totalStar > 2 ? 1 : 0)
        );
        container.add(containerStars);
      }

      if (chapter.PlayerChapter.length > 0 || i == 0) {
        const containerButton = this.rexUI.add.overlapSizer({
          space: { left: 235, right: 0, top: 75, bottom: 0 },
        });
        containerButton.add(this.add.image(0, 0, "imgOkBtn").setScale(0.5), {
          expand: false,
        });

        containerButton.add(
          this.add
            .text(0, 0, "Read", {
              fontFamily: "MouseMemoirs, sans-serif",
              fontSize: 42,
              color: "#000",
            })
            .setScale(0.6),
          {
            expand: false,
          }
        );
        container.add(containerButton);
        container.isReadable = true;
      } else {
        const containerLock = this.rexUI.add.overlapSizer({
          space: { left: 280, right: 0, top: 0, bottom: 0 },
        });
        let lockImg = this.add.sprite(0, 0, "sptLock").setScale(0.8);

        if (
          i > 0 &&
          this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
          this.chapters[i - 1].PlayerChapter.length > 0
        ) {
          containerLock.canUnlock = true;
          lockImg.setFrame(1);
        } else {
          lockImg.setFrame(0);
        }
        containerLock.add(lockImg, {
          expand: false,
          padding: { left: 0, right: 0, top: 0, bottom: 10 },
        });
        containerLock.add(this.add.image(0, 0, "imgStar").setScale(0.2), {
          expand: false,
          padding: { left: -15, right: 0, top: 90, bottom: 0 },
        });
        containerLock.add(
          this.add.text(0, 0, chapter.minStarToUnlock, {
            fontFamily: "MouseMemoirs, sans-serif",
            fontSize: 20,
            color: "#000",
          }),
          {
            expand: false,
            padding: { left: 20, right: 0, top: 90, bottom: 0 },
          }
        );

        container.add(containerLock);

        if (
          i > 0 &&
          this.book.PlayerBook[0].totalStar >= chapter.minStarToUnlock &&
          this.chapters[i - 1].PlayerChapter.length > 0
        ) {
          container.canUnlock = true;
        }
      }

      sizer.add(container);
    });

    return sizer;
  }

  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("ChaptersScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("BooksScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      books: this.books,
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "ChaptersScene",
    });
  }

  goStory(selectedChapter) {
    this.scene.stop("ChaptersScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChapterIntroScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: selectedChapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }

  unlockChapter(selectedChapter) {
    console.log(selectedChapter);
    this.scene.stop("ChaptersScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChapterUnlockScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: selectedChapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }

  infoDialog(txt) {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11)
      .setScrollFactor(0);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.6, 0.4)
      .setScrollFactor(0);

    let okBtn = this.add
      .image(240, 500, "imgOkBtn")
      .setScale(0.5)
      .setDepth(11)
      .setScrollFactor(0);
    let okBtnTxt = this.add
      .text(240, 500, "OK", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11)
      .setScrollFactor(0);

    let infoTxt = this.add
      .text(240, 320, txt, {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 18,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11)
      .setScrollFactor(0);

    let infoImg = this.add
      .image(240, 410, "imgFreezeMochi")
      .setScale(0.15)
      .setDepth(11)
      .setScrollFactor(0);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
      },
      this
    );
  }

  update() {
    //
  }
}
