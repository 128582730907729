import Phaser from "phaser";
import LoadingScene from "@/hamochiABC/ABC/scenes/LoadingScene";
import BooksScene from "@/hamochiABC/ABC/scenes/BooksScene";
import BookIntroScene from "@/hamochiABC/ABC/scenes/BookIntroScene";
import ChaptersScene from "@/hamochiABC/ABC/scenes/ChaptersScene";
import ChapterUnlockScene from "@/hamochiABC/ABC/scenes/ChapterUnlockScene";
import ChapterIntroScene from "@/hamochiABC/ABC/scenes/ChapterIntroScene";
import ChapterScoreScene from "@/hamochiABC/ABC/scenes/ChapterScoreScene";
import StoryLoadingScene from "@/hamochiABC/ABC/scenes/StoryLoadingScene";
import StoryScene from "@/hamochiABC/ABC/scenes/StoryScene";
import GameScene from "@/hamochiABC/ABC/scenes/GameScene";
import MapScene from "@/hamochiABC/ABC/scenes/MapScene";
import ItemScene from "@/hamochiABC/ABC/scenes/ItemScene";
import RestartScene from "@/hamochiABC/ABC/scenes/RestartScene";
import Settings from "@/hamochiABC/ABC/scenes/Settings";

import DropShadowPipelinePlugin from "phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js";
import AwaitLoaderPlugin from "phaser3-rex-plugins/plugins/awaitloader-plugin.js";
// import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [
        {
          key: "rexDropShadowPipeline",
          plugin: DropShadowPipelinePlugin,
          start: true,
        },
        {
          key: "rexAwaitLoader",
          plugin: AwaitLoaderPlugin,
          start: true,
        },
      ],
      // scene: [
      //   {
      //     key: "rexUI",
      //     plugin: RexUIPlugin,
      //     mapping: "rexUI",
      //   },
      // ],
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      BooksScene,
      BookIntroScene,
      ChaptersScene,
      ChapterIntroScene,
      ChapterScoreScene,
      ChapterUnlockScene,
      StoryLoadingScene,
      StoryScene,
      GameScene,
      MapScene,
      ItemScene,
      RestartScene,
      Settings,
    ],
  });
}

export default launch;
export { launch };
