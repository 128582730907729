import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";
import _ from "lodash";
// import Phaser from "phaser";

export default class MapScene extends Scene {
  constructor() {
    super({ key: "MapScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.isBackward = data.isBackward;
    this.isHorizontal = data.isHorizontal;
    this.isLife = data.isLife;
    this.isSteps = data.isSteps;
    this.isTime = data.isTime;
    this.lifeCount = data.lifeCount;
    this.stepsCount = data.stepsCount;
    this.timeCount = data.timeCount;
    this.timeChallenge = data.timeChallenge;
    this.cardDetails = data.cardDetails;
    this.isMonsterChase = data.isMonsterChase;
  }

  noOfRows = 6;
  noOfPlotsInARow = 5;
  avatarObject = [];
  avatarX = 240;
  avatarY = 780;
  create() {
    this.events.on("resume", this.onResume, this);
    this.registerCards();
    this.registerAnims();
    this.registerSounds();

    this.bg = this.add.rectangle(240, 427, 480, 854, 0xd1b89f);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this)
      .setDepth(3);

    this.settingBtn.setScrollFactor(0);

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.6)
      .setDepth(3)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);
    this.closeBtn.setScrollFactor(0);

    this.heartImgs = [];
    for (let count = 0; count < this.lifeCount; count++) {
      this.heartImgs.push(
        this.add.image(300 + 30 * count, 45, "imgHeart").setScale(0.6)
      );
    }

    if (this.isSteps) {
      this.stepsImg = this.add.image(40, 140, "imgSteps").setScale(0.6);
      this.stepsText = this.add.text(70, 130, "X " + this.stepsCount, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 16,
        color: "#000",
      });
    }

    this.coinCount = 0;
    this.keyCount = 0;
    this.plierCount = 0;

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setScale(0.15)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.15)
              .setAlpha(0.3)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, this.avatarY, key)
              .setScale(0.15)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.endPlot = this.add
      .sprite(240, 160, "sptNode")
      .setScale(1)
      .setOrigin(0.5, 1)
      .setDepth(2);

    this.startPlot = this.add
      .image(this.avatarX, this.avatarY, "imgNode")
      .setScale(1)
      .setOrigin(0.5, 0.4)
      .setDepth(2);

    this.startPlot.isStart = true;

    this.pathGraphics = this.add.graphics({
      lineStyle: { width: 6, color: 0xffffff, alpha: 0.6 },
      fillStyle: { color: 0xff0000 },
    });

    this.generateMap(
      5,
      5,
      this.cameras.main.height / this.noOfRows - 30,
      this.cameras.main.width / this.noOfPlotsInARow,
      this.cameras.main.height,
      3
    );

    this.dark = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0)
      .setDepth(9);
    this.optionPanel = this.add
      .image(0, 0, "imgOptionPanel")
      .setDepth(9)
      .setScale(0);
  }

  update() {
    //
  }

  optionDone(option) {
    if (option == "ABC_1" || option == "ABC_2") {
      this.tweens.add({
        targets: this.optionPanel,
        scaleX: 0,
        scaleY: 0,
        x: this.currentPlot.x,
        y: this.currentPlot.y,
        ease: "Power1",
        duration: 300,
        onStart: () => {
          this.dark.setAlpha(0);
          this.eventHeadding.destroy();
          this.eventDescription.destroy();
          this.eventImage.destroy();
          this.eventOptions.forEach((option) => {
            option.destroy();
          });
          this.eventOptions = [];

          // this.okBtn.setScale(0);
          // this.okBtnTxt.setScale(0);
        },
        onComplete: () => {
          if (this.currentPlot.card) {
            this.currentPlot.card.destroy();
            this.currentPlot.card = null;
          }
          if (option == "ABC_1") {
            let x = [
              {
                id: 2,
                translatableName: '{"en":"Fishes","zh":"鱼","ms":"ikan"}',
                key: "D00020",
                imageUrl:
                  "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/deck/D00020/1713853629.png",
                imageBuild: 2,
                isPublished: true,
                timestampCreated: "2024-04-23T01:20:14.575Z",
                timestampUpdated: "2024-04-23T06:32:43.438Z",
                timestampDeleted: null,
                PlayerDeckRecord: [
                  {
                    totalCorrect: 3,
                    totalQuestion: 5,
                  },
                ],
                Card: [
                  {
                    Vocab: {
                      id: 1,
                      translatableName:
                        '{"en":"starfish","zh":"水果","ms":"buah-buahan"}',
                      key: "V00010",
                      imageUrl:
                        "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/vocab/V00010/1713835230.png",
                      imageBuild: 1,
                      timestampCreated: "2024-04-23T01:20:39.261Z",
                      timestampUpdated: "2024-04-23T06:32:43.560Z",
                      timestampDeleted: null,
                    },
                  },
                ],
              },
            ];

            this.scene.pause();
            this.scene.launch("QScene", {
              avatar: this.avatar,
              vocab: this.vocab,
              deck: _.shuffle(x[0].Card),
              env: this.env,
              flashcards: x,
              flashCard: x[0],
            });
          }

          if (option == "ABC_2") {
            let x = [
              {
                id: 2,
                translatableName: '{"en":"Fishes","zh":"鱼","ms":"ikan"}',
                key: "D00020",
                imageUrl:
                  "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/deck/D00020/1713853629.png",
                imageBuild: 2,
                isPublished: true,
                timestampCreated: "2024-04-23T01:20:14.575Z",
                timestampUpdated: "2024-04-23T06:32:43.438Z",
                timestampDeleted: null,
                PlayerDeckRecord: [
                  {
                    totalCorrect: 3,
                    totalQuestion: 5,
                  },
                ],
                Card: [
                  {
                    Vocab: {
                      id: 1,
                      translatableName:
                        '{"en":"starfish","zh":"水果","ms":"buah-buahan"}',
                      key: "V00010",
                      imageUrl:
                        "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/vocab/V00010/1713835230.png",
                      imageBuild: 1,
                      timestampCreated: "2024-04-23T01:20:39.261Z",
                      timestampUpdated: "2024-04-23T06:32:43.560Z",
                      timestampDeleted: null,
                    },
                  },
                ],
              },
            ];

            this.scene.pause();
            this.scene.launch("QScene", {
              avatar: this.avatar,
              vocab: this.vocab,
              deck: _.shuffle(x[0].Card),
              env: this.env,
              flashcards: x,
              flashCard: x[0],
            });
          }
        },
      });
    }

    // if (this.cardEvent.type == "bomb" && option == 1 && this.plierCount == 0) {
    //   this.infoDialog("Opps... not enough plier", "imgPlier");
    // } else if (
    //   this.cardEvent.type == "chest" &&
    //   option == 1 &&
    //   this.keyCount == 0
    // ) {
    //   this.infoDialog("Opps... not enough key", "imgKey");
    // } else {
    //   this.tweens.add({
    //     targets: this.optionPanel,
    //     scaleX: 0,
    //     scaleY: 0,
    //     x: this.currentPlot.x,
    //     y: this.currentPlot.y,
    //     ease: "Power1",
    //     duration: 300,
    //     onStart: () => {
    //       this.dark.setAlpha(0);
    //       this.eventHeadding.destroy();
    //       this.eventDescription.destroy();
    //       this.eventImage.destroy();
    //       this.orText.destroy();
    //       this.eventOptions.forEach((option) => {
    //         option.destroy();
    //       });
    //       this.eventOptions = [];

    //       // this.okBtn.setScale(0);
    //       // this.okBtnTxt.setScale(0);
    //     },
    //     onComplete: () => {
    //       if (this.currentPlot.card) {
    //         this.currentPlot.card.destroy();
    //         this.currentPlot.card = null;
    //       }

    //       if (this.cardEvent.type == "monstor") {
    //         if (option == 0) {
    //           this.scene.pause();
    //           this.scene.launch("FightScene", {
    //             avatar: this.avatar,
    //           });
    //         }
    //         if (option == 1) {
    //           this.monstorBack();
    //         }
    //       } else if (
    //         this.cardEvent.type == "enemy" ||
    //         this.cardEvent.type == "thief"
    //       ) {
    //         if (option == 0) {
    //           this.scene.pause();
    //           this.scene.launch("KombatScene", {
    //             avatar: this.avatar,
    //             name: "You",
    //           });
    //         }
    //         if (option == 1) {
    //           if (this.cardEvent.type == "enemy") this.enemyBack();
    //           if (this.cardEvent.type == "thief") this.reduceCoin(10, true);
    //         }
    //       } else if (option == 0) {
    //         let x = [
    //           {
    //             id: 2,
    //             translatableName: '{"en":"Fishes","zh":"鱼","ms":"ikan"}',
    //             key: "D00020",
    //             imageUrl:
    //               "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/deck/D00020/1713853629.png",
    //             imageBuild: 2,
    //             isPublished: true,
    //             timestampCreated: "2024-04-23T01:20:14.575Z",
    //             timestampUpdated: "2024-04-23T06:32:43.438Z",
    //             timestampDeleted: null,
    //             PlayerDeckRecord: [
    //               {
    //                 totalCorrect: 3,
    //                 totalQuestion: 5,
    //               },
    //             ],
    //             Card: [
    //               {
    //                 Vocab: {
    //                   id: 1,
    //                   translatableName:
    //                     '{"en":"starfish","zh":"水果","ms":"buah-buahan"}',
    //                   key: "V00010",
    //                   imageUrl:
    //                     "https://dev-hg-space.sgp1.digitaloceanspaces.com/dev3_game_niroshan/games/vocab/V00010/1713835230.png",
    //                   imageBuild: 1,
    //                   timestampCreated: "2024-04-23T01:20:39.261Z",
    //                   timestampUpdated: "2024-04-23T06:32:43.560Z",
    //                   timestampDeleted: null,
    //                 },
    //               },
    //             ],
    //           },
    //         ];

    //         this.scene.pause();
    //         this.scene.launch("QScene", {
    //           avatar: this.avatar,
    //           vocab: this.vocab,
    //           deck: _.shuffle(x[0].Card),
    //           env: this.env,
    //           flashcards: x,
    //           flashCard: x[0],
    //         });
    //       }
    //       if (this.cardEvent.type == "event") {
    //         if (option == 1) {
    //           this.scene.pause();
    //           this.scene.launch("PuzzleScene", {
    //             avatar: this.avatar,
    //             noOfCuts: 3,
    //             fimage:
    //               this.cardEvent.xtype == "positive" ? "imgPZT" : "imgPZS",
    //           });
    //         }
    //         if (option == 2 && this.cardEvent.xtype == "negative") {
    //           this.reduceHeart();
    //         }
    //       }
    //       if (this.cardEvent.type == "bomb") {
    //         if (option == 1) {
    //           this.rewardIndication(
    //             this.currentPlot.x,
    //             this.currentPlot.y,
    //             1,
    //             false,
    //             "imgPlier"
    //           );

    //           if (this.playerMask) {
    //             this.playerMask.destroy();
    //           }
    //           this.playerMask = this.add
    //             .image(
    //               this.avatarObject[0].x,
    //               this.avatarObject[0].y,
    //               "maskHappyx"
    //             )
    //             .setScale(0.15)
    //             .setDepth(7);

    //           this.time.delayedCall(1000, () => {
    //             this.plierCount -= 1;
    //             this.plierText.setText("X " + this.plierCount);
    //           });
    //         }
    //         if (option == 2) {
    //           this.bombing();
    //         }
    //       }
    //       if (this.cardEvent.type == "chest" && option == 1) {
    //         this.rewardDialog(true);
    //       }

    //       if (
    //         ((this.cardEvent.type == "chest" ||
    //           (this.cardEvent.type == "event" &&
    //             this.cardEvent.xtype == "positive")) &&
    //           option == 2) ||
    //         ((this.cardEvent.type == "filler" ||
    //           this.cardEvent.type == "trump") &&
    //           option == 1)
    //       ) {
    //         this.time.delayedCall(1000, () => {
    //           if (this.coinCount > 2) this.enemyAttack();
    //           else this.activeNextCards();
    //         });
    //       }
    //     },
    //   });
    // }
  }

  onResume(scene, data) {
    if (data) {
      if (data.scene == "fight") {
        if (data.isWin) {
          if (this.cardEvent.type == "monstor") this.monstorBack(true);

          if (this.cardEvent.type == "enemy") this.enemyBack(true);

          if (this.cardEvent.type == "thief") this.increaseCoin(20, true);
          // monstor faint return back
          // get reward coin
        } else {
          if (this.cardEvent.type == "monstor") this.monstorBack();

          if (this.cardEvent.type == "enemy") this.enemyBack();

          if (this.cardEvent.type == "thief") this.reduceCoin(5, true);
          // reduce heart
          // monstor go back
        }
      }
      if (data.isCorrectAnswer) {
        if (this.cardEvent.type == "trump") {
          if (this.cardEvent.gain == "heart") {
            this.rewardIndication(
              this.currentPlot.x,
              this.currentPlot.y,
              1,
              true,
              "imgHeart"
            );
            this.time.delayedCall(1000, () => {
              this.lifeCount += 1;
              this.heartText.setText("X " + this.lifeCount);
            });
          }
          if (this.cardEvent.gain == "key") {
            this.rewardIndication(
              this.currentPlot.x,
              this.currentPlot.y,
              1,
              true,
              "imgKey"
            );
            this.time.delayedCall(1000, () => {
              this.keyCount += 1;
              this.keyText.setText("X " + this.keyCount);
            });
          }
          if (this.cardEvent.gain == "plier") {
            this.rewardIndication(
              this.currentPlot.x,
              this.currentPlot.y,
              1,
              true,
              "imgPlier"
            );
            this.time.delayedCall(1000, () => {
              this.plierCount += 1;
              this.plierText.setText("X " + this.plierCount);
            });
          }
          if (this.cardEvent.gain == "x2") {
            this.rewardIndication(
              this.currentPlot.x,
              this.currentPlot.y,
              0,
              true,
              "imgX2"
            );
            this.time.delayedCall(1000, () => {
              this.isX2 = true;
              this.x2Img.setAlpha(1);
            });
          }
          if (this.cardEvent.gain == "swapB") {
            this.time.delayedCall(1000, () => {
              this.plots.forEach((rows) => {
                rows.forEach((plot) => {
                  if (plot.card && plot.card.type == "sptCardBomb") {
                    plot.card.destroy();
                    plot.card = this.add
                      .sprite(plot.x, plot.y, "sptCardFiller")
                      .setScale(0.8)
                      .setDepth(2);
                    plot.card.type = "sptCardFiller";
                  }
                });
              });
            });
          }
        }
        if (
          this.cardEvent.type == "bomb" ||
          (this.cardEvent.type == "event" && this.cardEvent.xtype == "negative")
        ) {
          this.increaseCoin(1);
        }
        if (
          this.cardEvent.type == "chest" ||
          (this.cardEvent.type == "event" && this.cardEvent.xtype == "positive")
        ) {
          this.rewardDialog();
        } else {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappy")
            .setScale(0.15)
            .setDepth(7);
          this.time.delayedCall(1500, () => {
            this.activeNextCards();
          });
        }
        if (this.cardEvent.type == "filler") {
          this.rewardIndication(
            this.currentPlot.x,
            this.currentPlot.y,
            1,
            true,
            "imgGem"
          );
        }
      } else {
        if (
          this.cardEvent.type == "trump" ||
          this.cardEvent.type == "chest" ||
          this.cardEvent.type == "filler" ||
          (this.cardEvent.type == "event" && this.cardEvent.xtype == "positive")
        ) {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskSad")
            .setScale(0.15)
            .setDepth(7);

          this.time.delayedCall(1500, () => {
            this.activeNextCards();
          });
        }

        if (this.cardEvent.type == "bomb") {
          this.bombing();
        }

        if (
          this.cardEvent.type == "event" &&
          this.cardEvent.xtype == "negative"
        ) {
          this.reduceHeart();
        }
      }
    }
  }

  generatePlots(
    noOfRows,
    noOfPlotsInARow,
    plotHeight,
    plotWidth,
    areaHeight,
    noOfPlotsInFirstRow
  ) {
    this.plots = [];
    for (let i = 0; i < noOfRows; i++) {
      this.plots[i] = [];
      for (let j = 0; j < noOfPlotsInARow; j++) {
        let plot = {
          x: plotWidth * j + plotWidth / 2,
          y: areaHeight - plotHeight * (i + 1) - 30,
          status: 0,
        };

        this.plots[i].push(plot);
      }
    }

    let firstRow = [];
    for (let i = 0; i < noOfPlotsInARow; i++) {
      firstRow.push(i);
    }
    firstRow = [1, 2, 3];

    for (let i = 0; i < noOfPlotsInFirstRow; i++) {
      let randomPlot = this.getRandomAndRemove(firstRow);
      this.plots[0][randomPlot].status = 1;
    }

    for (let i = 0; i < noOfRows - 1; i++) {
      for (let j = 0; j < noOfPlotsInARow; j++) {
        if (this.plots[i][j].status == 1) {
          let ranomNextPlot;

          // if top left has a plot
          if (j != 0 && this.plots[i + 1][j - 1].status == 1) {
            if (j == noOfPlotsInARow - 1) {
              ranomNextPlot = j;
            } else {
              ranomNextPlot = this.getRandom([j, j + 1]);
            }
          } else if (this.plots[i + 1][j].status == 1) {
            // if top stright has a plot
            if (j == noOfPlotsInARow - 1) {
              ranomNextPlot = j;
            } else {
              ranomNextPlot = j + 1;
            }
          } else {
            if (j == noOfPlotsInARow - 1) {
              if (this.plots[i + 1][j - 1].status == 0) {
                ranomNextPlot = j - 1;
              } else {
                ranomNextPlot = this.getRandom([j - 1, j]);
              }
            } else if (j == 0) {
              if (this.plots[i][j + 1].status == 0) {
                ranomNextPlot = j + 1;
              } else {
                ranomNextPlot = this.getRandom([j, j + 1]);
              }
            } else {
              if (
                this.plots[i][j + 1].status == 0 &&
                this.plots[i][j - 1].status == 0
              ) {
                ranomNextPlot = j;
              } else if (this.plots[i][j + 1].status == 0) {
                if (this.plots[i + 1][j - 1].status == 0) {
                  ranomNextPlot = j - 1;
                } else {
                  ranomNextPlot = j + 1;
                }
              } else if (this.plots[i][j - 1].status == 0) {
                ranomNextPlot = j - 1;
              } else {
                if (this.plots[i + 1][j - 1].status == 0) {
                  ranomNextPlot = this.getRandom([j - 1, j]);
                } else {
                  ranomNextPlot = this.getRandom([j - 1, j, j + 1]);
                }
              }
            }
          }
          this.plots[i + 1][ranomNextPlot].status = 1;
          this.pathGraphics.lineBetween(
            this.plots[i + 1][ranomNextPlot].x,
            this.plots[i + 1][ranomNextPlot].y,
            this.plots[i][j].x,
            this.plots[i][j].y
          );
          if (ranomNextPlot < j) {
            this.plots[i + 1][ranomNextPlot].isConnectedR = true;
          } else if (ranomNextPlot > j) {
            this.plots[i + 1][ranomNextPlot].isConnectedL = true;
          } else {
            this.plots[i + 1][ranomNextPlot].isConnectedM = true;
          }
        }
      }
    }
  }

  generateMap(
    noOfRows,
    noOfPlotsInARow,
    plotHeight,
    plotWidth,
    areaHeight,
    noOfPlotsInFirstRow
  ) {
    this.generatePlots(
      noOfRows,
      noOfPlotsInARow,
      plotHeight,
      plotWidth,
      areaHeight,
      noOfPlotsInFirstRow
    );

    this.generatePaths(noOfRows, noOfPlotsInARow);
    this.assignCards(noOfRows, noOfPlotsInARow);
  }

  generatePaths(noOfRows, noOfPlotsInARow) {
    for (let i = 0; i < noOfRows; i++) {
      for (let j = 0; j < noOfPlotsInARow; j++) {
        if (this.plots[i][j].status == 1) {
          if (i == 0) {
            this.pathGraphics.lineBetween(
              this.avatarX,
              this.avatarY + 20,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
          }
          if (i == noOfRows - 1) {
            this.pathGraphics.lineBetween(
              this.endPlot.x,
              this.endPlot.y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
          }
          if (
            j != noOfPlotsInARow - 1 &&
            this.plots[i][j + 1].status == 1 &&
            !this.plots[i][j + 1].isConnectedSR &&
            this.isHorizontal
          ) {
            this.pathGraphics.lineBetween(
              this.plots[i][j + 1].x,
              this.plots[i][j + 1].y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
            this.plots[i][j + 1].isConnectedSR = true;
          }
          if (
            j != 0 &&
            this.plots[i][j - 1].status == 1 &&
            !this.plots[i][j - 1].isConnectedSL &&
            this.isHorizontal
          ) {
            this.pathGraphics.lineBetween(
              this.plots[i][j - 1].x,
              this.plots[i][j - 1].y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
            this.plots[i][j - 1].isConnectedSL = true;
          }
        }
      }
    }

    for (let i = 0; i < noOfRows - 1; i++) {
      for (let j = 0; j < noOfPlotsInARow; j++) {
        if (this.plots[i][j].status == 1) {
          if (
            this.plots[i + 1][j].status == 1 &&
            !this.plots[i + 1][j].isConnectedM
          ) {
            this.pathGraphics.lineBetween(
              this.plots[i + 1][j].x,
              this.plots[i + 1][j].y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
            this.plots[i + 1][j].isConnectedM = true;
          }

          if (
            j != noOfPlotsInARow - 1 &&
            this.plots[i + 1][j + 1].status == 1 &&
            !this.plots[i + 1][j + 1].isConnectedL &&
            ((this.plots[i + 1][j].status == 1 &&
              !this.plots[i + 1][j].isConnectedR) ||
              this.plots[i + 1][j].status == 0)
          ) {
            this.pathGraphics.lineBetween(
              this.plots[i + 1][j + 1].x,
              this.plots[i + 1][j + 1].y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
            this.plots[i + 1][j + 1].isConnectedL = true;
          }

          if (
            j != 0 &&
            this.plots[i + 1][j - 1].status == 1 &&
            !this.plots[i + 1][j - 1].isConnectedR &&
            ((this.plots[i + 1][j].status == 1 &&
              !this.plots[i + 1][j].isConnectedL) ||
              this.plots[i + 1][j].status == 0)
          ) {
            this.pathGraphics.lineBetween(
              this.plots[i + 1][j - 1].x,
              this.plots[i + 1][j - 1].y,
              this.plots[i][j].x,
              this.plots[i][j].y
            );
            this.plots[i + 1][j - 1].isConnectedR = true;
          }
        }
      }
    }
  }

  assignCards(noOfRows, noOfPlotsInARow) {
    for (let i = 0; i < noOfRows; i++) {
      let cards;
      if (i == 1) {
        cards = ["sptCardBomb", "sptCardEvent", "sptCardFiller"];
      } else if (i == 2) {
        cards = ["sptCardFiller", "sptCardBomb", "sptCardChest"];
      } else if (i == 3) {
        cards = ["sptCardFiller", "sptCardBomb", "sptCardEvent", "sptCardDie"];
      } else {
        cards = ["sptCardBomb", "sptCardEvent", "sptCardChest"];
      }

      for (let j = 0; j < noOfPlotsInARow; j++) {
        if (this.plots[i][j].status == 1) {
          this.plots[i][j].node = this.add
            .sprite(this.plots[i][j].x, this.plots[i][j].y, "sptNode")
            .setScale(0.8)
            .setOrigin(0.5, 0.8)
            .setDepth(2);

          let cardType;
          // if (i == 0) {
          //   cardType = "sptCardTrump";
          // } else {
          cardType = this.getRandomAndRemove(cards);
          // }

          this.plots[i][j].card = this.add
            .sprite(
              this.plots[i][j].x,
              this.plots[i][j].y - 30,
              this.cardDetails == 1
                ? "sptCardHide"
                : this.cardDetails == 2
                ? Math.random() < 0.5
                  ? "sptCardHide"
                  : cardType
                : cardType
            )
            .setScale(0.8)
            .setDepth(2);
          this.plots[i][j].card.type = cardType;

          this.plots[i][j].i = i;
          this.plots[i][j].j = j;

          if (i == 0) {
            this.activatePlot(this.plots[i][j]);
          }
        }
      }
    }
  }

  movePlayer(plot) {
    if (this.counterEvent) {
      this.time.removeEvent(this.counterEvent);
      this.coundownTxt.destroy();
      this.counterEvent.destroy();
    }
    this.tweens.add({
      targets: this.avatarObject,
      x: plot.x,
      y: plot.y - 30,
      ease: "Power1",
      duration: 400,
      onComplete: () => {
        this.previousPlot = this.currentPlot
          ? this.currentPlot
          : this.startPlot;
        this.currentPlot = plot;
        this.afterMove();
      },
    });
  }

  infoDialog(txt, image) {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.6, 0.4);

    let okBtn = this.add.image(240, 500, "imgOkBtn").setScale(0.5).setDepth(11);
    let okBtnTxt = this.add
      .text(240, 500, "OK", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoTxt = this.add
      .text(240, 300, txt, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoImg = this.add.image(240, 400, image).setScale(2).setDepth(11);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
      },
      this
    );
  }

  rewardDialog(useKey = false) {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.8, 0.6);

    let okBtn = this.add.image(240, 550, "imgOkBtn").setScale(0.5).setDepth(11);
    let okBtnTxt = this.add
      .text(240, 550, "OK", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoTxt = this.add
      .text(240, 250, "Treasure!!!", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoImg = this.add.image(240, 360, "imgCO").setScale(0.3).setDepth(11);
    let rewardImg = this.add
      .image(200, 470, "imgCoin")
      .setScale(1)
      .setDepth(11);
    let rewardTxt = this.add
      .text(260, 470, "+ 50", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 24,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        rewardTxt.destroy();
        rewardImg.destroy();
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
        if (useKey) {
          this.rewardIndication(
            this.currentPlot.x,
            this.currentPlot.y,
            1,
            false,
            "imgKey"
          );
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappyx")
            .setScale(0.15)
            .setDepth(7);
          this.time.delayedCall(1000, () => {
            this.keyCount -= 1;
            this.keyText.setText("X " + this.keyCount);
          });
        } else {
          this.increaseCoin(1);
        }
        this.time.delayedCall(1000, () => {
          this.increaseCoin(50, true);
        });
      },
      this
    );
  }

  potionDialog() {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.8, 0.6);

    let okBtn = this.add.image(240, 550, "imgOkBtn").setScale(0.5).setDepth(11);
    let okBtnTxt = this.add
      .text(240, 550, "OK", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoTxt = this.add
      .text(240, 250, "Treasure!!!", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoImg = this.add.image(240, 360, "imgCO").setScale(0.3).setDepth(11);
    let rewardImg = this.add
      .image(240, 470, "imgPotion")
      .setScale(1)
      .setDepth(11);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        rewardImg.destroy();
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
        if (this.currentPlot.card) {
          this.currentPlot.card.destroy();
          this.currentPlot.card = null;
        }
        this.time.delayedCall(1000, () => {
          this.increaseHeart();
        });
      },
      this
    );
  }

  dieDialog() {
    let darkx = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(11);
    let panelx = this.add
      .image(240, 400, "imgOptionPanel")
      .setDepth(11)
      .setScale(0.8, 0.6);

    let okBtn = this.add.image(240, 550, "imgOkBtn").setScale(0.5).setDepth(11);
    let okBtnTxt = this.add
      .text(240, 550, "OK", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoTxt = this.add
      .text(240, 250, "Danger!!!", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 28,
        color: "#000",
        wordWrap: { width: 200 },
        align: "center",
      })
      .setOrigin(0.5)
      .setDepth(11);

    let infoImg = this.add.image(240, 360, "imgDie").setScale(0.3).setDepth(11);

    okBtn.setInteractive({ useHandCursor: true }).on(
      "pointerdown",
      () => {
        infoTxt.destroy();
        okBtnTxt.destroy();
        panelx.destroy();
        darkx.destroy();
        infoImg.destroy();
        okBtn.destroy();
        this.time.delayedCall(1000, () => {
          this.reduceHeart(true);
        });
      },
      this
    );
  }

  monstorFollow() {
    this.tweens.add({
      targets: [this.monstor, this.monstor.event],
      x: this.previousPlot.x,
      y: this.previousPlot.y - 30,
      ease: "Power1",
      duration: 400,
      onComplete: () => {
        //
      },
    });
  }

  monstorAttack() {
    this.cardEvent = this.monstorAttackCard;
    this.tweens.add({
      targets: [this.monstor, this.monstor.effect, this.monstor.dizzy],
      x: this.currentPlot.x,
      y: this.currentPlot.y - 30,
      ease: "Power1",
      duration: 400,
      onComplete: () => {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerMask = this.add
          .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskMad")
          .setScale(0.15)
          .setDepth(7);
        this.monstor.effect.play("animSmokeI");
        this.time.delayedCall(1500, () => {
          this.openOptionPanel();
        });
      },
    });
  }

  enemyAttack() {
    this.enemy = this.add
      .image(
        this.previousPlot.x,
        this.previousPlot.isStart
          ? this.previousPlot.y
          : this.previousPlot.y - 30,
        "imgEny"
      )
      .setScale(0.6)
      .setDepth(6)
      .setAlpha(0);
    this.enemy.dizzy = this.add
      .sprite(this.enemy.x, this.enemy.y, "sptDizzyAnim")
      .setFrame(2)
      .setDepth(6)
      .setOrigin(0.5, 1)
      .setScale(0.8);
    this.enemy.effect = this.add
      .sprite(this.enemy.x, this.enemy.y, "sptSmoke")
      .setScale(0.5)
      .setDepth(7)
      .setFrame(7);
    this.enemy.effect.play("animSmoke");
    this.time.delayedCall(1500, () => {
      this.enemy.setAlpha(1);
      this.enemy.effect.setFrame(7);

      this.cardEvent = this.enemyAttackCard;
      this.tweens.add({
        targets: [this.enemy, this.enemy.effect, this.enemy.dizzy],
        x: this.currentPlot.x,
        y: this.currentPlot.y - 30,
        delay: 500,
        ease: "Power1",
        duration: 400,
        onComplete: () => {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskMad")
            .setScale(0.15)
            .setDepth(7);
          this.enemy.effect.play("animSmokeI");
          this.time.delayedCall(1500, () => {
            this.openOptionPanel();
          });
        },
      });
    });
  }

  monstorBack(isFaint = false) {
    this.tweens.add({
      targets: [this.monstor, this.monstor.effect, this.monstor.dizzy],
      x: this.previousPlot.x,
      y: this.previousPlot.isStart
        ? this.previousPlot.y
        : this.previousPlot.y - 30,
      ease: "Power1",
      duration: 400,
      onStart: () => {
        this.monstor.effect.stop("animSmokeI");
        this.monstor.effect.setFrame(7);
        if (isFaint) {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappy")
            .setScale(0.15)
            .setDepth(7);

          this.monstor.dizzy.play("animDizzy");
        } else {
          this.reduceHeart();
        }
      },
      onComplete: () => {
        if (isFaint) this.increaseCoin(2, true);
      },
    });
  }

  enemyBack(isFaint = false) {
    this.tweens.add({
      targets: [this.enemy, this.enemy.effect, this.enemy.dizzy],
      x: this.previousPlot.x,
      y: this.previousPlot.isStart
        ? this.previousPlot.y
        : this.previousPlot.y - 30,
      ease: "Power1",
      duration: 400,
      onStart: () => {
        this.enemy.effect.stop("animSmokeI");
        this.enemy.effect.setFrame(7);
        if (isFaint) {
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappy")
            .setScale(0.15)
            .setDepth(7);

          this.enemy.dizzy.play("animDizzy");
        } else {
          this.reduceCoin(2);
        }
      },
      onComplete: () => {
        this.time.delayedCall(500, () => {
          this.enemy.effect.play("animSmoke");
          this.time.delayedCall(1500, () => {
            this.enemy.effect.destroy();
            this.enemy.dizzy.destroy();
            this.enemy.destroy();
          });

          if (isFaint) {
            this.increaseCoin(3, true);
          }
        });
      },
    });
  }

  countdown() {
    let counter = this.timeCount;
    this.coundownTxt = this.add.text(400, 780, counter, {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 48,
      color: "#000f1f",
    });
    this.counterEvent = this.time.addEvent({
      delay: 1000,
      callback: () => {
        this.coundownTxt.setText(--counter);
        if (counter == 0) {
          this.deactivatePlots();
          this.time.removeEvent(this.counterEvent);
          this.coundownTxt.destroy();
          this.counterEvent.destroy();

          if (this.playerMask) {
            this.playerMask.destroy();
          }
          this.playerMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskFear")
            .setScale(0.15)
            .setDepth(10);

          if (this.timeChallenge == 1) {
            let mgob;
            let type = this.getRandom([
              "sptAttackWater",
              "sptAttackFire",
              "sptAttackThunder",
              "sptMiniAttackThunder",
            ]);
            if (type == "sptAttackFire") {
              mgob = this.add
                .sprite(
                  this.currentPlot.x,
                  this.currentPlot.y - 50,
                  "sptAttackFire"
                )
                .setScale(0.4)
                .setDepth(9);

              mgob.play("animAttackFire");
            }
            if (type == "sptAttackThunder") {
              mgob = this.add
                .sprite(
                  this.currentPlot.x,
                  this.currentPlot.y - 40,
                  "sptAttackThunder"
                )
                .setScale(0.4)
                .setDepth(9);

              mgob.play("animAttackThunder");
            }
            if (type == "sptMiniAttackThunder") {
              mgob = this.add
                .sprite(
                  this.currentPlot.x,
                  this.currentPlot.y - 40,
                  "sptMiniAttackThunder"
                )
                .setScale(0.4)
                .setDepth(9);

              mgob.play("animMiniAttackThunder");
            }
            if (type == "sptAttackWater") {
              mgob = this.add
                .sprite(
                  this.currentPlot.x,
                  this.currentPlot.y - 40,
                  "sptAttackWater"
                )
                .setScale(0.4)
                .setDepth(9);

              mgob.play("animAttackWater");
            }

            this.time.delayedCall(1500, () => {
              if (this.playerMask) {
                this.playerMask.destroy();
              }
              this.tweens.add({
                targets: this.avatarObject,
                alpha: 0,
                duration: 100,
                yoyo: true,
                repeat: 3,
                onComplete: () => {
                  this.reduceHeart();
                },
              });
            });
          } else {
            this.monstorAttack();
          }
        }
      },
      loop: true,
    });
  }

  afterMove() {
    if (this.isSteps) this.reduceSteps();

    if (this.currentPlot.card) {
      this.tweens.add({
        targets: this.currentPlot.card,
        scaleX: -0.8,
        ease: "Power1",
        duration: 600,
        onComplete: () => {
          if (this.currentPlot.card.type == "sptCardChest") {
            this.cardEvent = this.getRandom(this.chestCards);
          } else if (this.currentPlot.card.type == "sptCardTrump") {
            // this.cardEvent = this.getRandomAndRemove(this.trumpCards);
            this.cardEvent = this.trumpCards[4];
          } else if (this.currentPlot.card.type == "sptCardEvent") {
            this.cardEvent = this.getRandomAndRemove(this.eventCards);
          } else if (this.currentPlot.card.type == "sptCardBomb") {
            this.cardEvent = this.getRandom(this.bombCards);
          } else if (this.currentPlot.card.type == "sptCardThief") {
            this.cardEvent = this.getRandom(this.thiefAttackCard);
          } else if (this.currentPlot.card.type == "sptCardDie") {
            this.cardEvent = this.getRandom(this.dangerCards);
          } else {
            this.cardEvent = this.getRandom(this.fillerCards);
          }

          if (this.currentPlot.card.type == "sptCardChest") {
            this.potionDialog();
          } else if (this.currentPlot.card.type == "sptCardDie") {
            this.dieDialog();
          } else {
            this.openOptionPanel();
          }
        },
      });
    } else {
      this.time.delayedCall(1000, () => {
        this.activeNextCards();
      });
    }
    this.deactivatePlots();
  }

  openOptionPanel() {
    this.dark.setAlpha(0.7);
    this.optionPanel.x = this.currentPlot.x;
    this.optionPanel.y = this.currentPlot.y;
    this.tweens.add({
      targets: this.optionPanel,
      scaleX: 1,
      scaleY: 1,
      x: this.cameras.main.width / 2,
      y: this.cameras.main.height / 2,
      ease: "Power1",
      duration: 300,
      onComplete: () => {
        this.eventHeadding = this.add
          .text(240, 160, this.cardEvent.title, {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 32,
            color: "#000",
          })
          .setOrigin(0.5)
          .setDepth(9);

        this.eventImage = this.add
          .image(240, 300, this.cardEvent.image)
          .setDepth(9);

        this.eventDescription = this.add
          .text(240, 450, this.cardEvent.description, {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 20,
            color: "#000",
            wordWrap: { width: 250 },
            align: "center",
          })
          .setOrigin(0.5)
          .setDepth(9);

        this.eventOptions = [];
        this.cardEvent.options.forEach((option, i) => {
          this.eventOptions.push(
            this.add
              .image(240, 540 + i * 70, "imgOptionBtn")
              .setScale(0.35)
              .setDepth(9)
              .setInteractive({ useHandCursor: true })
              .on("pointerdown", () => this.optionDone(option.key), this)
          );
          this.eventOptions.push(
            this.add
              .text(240, 540 + i * 70, option.title, {
                fontFamily: "Sassoon, sans-serif",
                fontSize: 20,
                color: "#000",
              })
              .setOrigin(0.5)
              .setDepth(9)
          );
        });
      },
    });
  }

  deactivatePlots() {
    this.plots.forEach((row) => {
      row.forEach((plot) => {
        if (plot.card && plot.node) {
          plot.card.removeInteractive().setFrame(0);
          plot.card.off("pointerdown");
          plot.node.removeInteractive().setFrame(0);
        } else if (plot.node) {
          plot.node.removeInteractive().setFrame(0);
          plot.node.off("pointerdown");
        }
      });
    });
    this.endPlot.removeInteractive().setFrame(0);
    this.endPlot.off("pointerdown");
  }

  activeNextCards(isMonsterBack) {
    if (this.isSteps && this.stepsCount == 0) {
      this.add
        .text(240, this.cameras.main.scrollY + 500, "Game Over", {
          color: "#ffffff",
          fontSize: "50px",
          fontFamily: "Sassoon, sans-serif",
          stroke: "#000000",
          strokeThickness: 5,
        })
        .setDepth(8)
        .setShadow(3, 3, "#000", 0, true, true)
        .setOrigin(0.5);
      this.time.delayedCall(1500, () => {
        router.go();
      });
    } else {
      if (this.isTime && !isMonsterBack) this.countdown();
      if ((this.isTime && this.timeChallenge == 2) || this.isMonsterChase) {
        if (!this.monstor) {
          this.monstor = this.add
            .sprite(this.startPlot.x, this.startPlot.y, "sptBoss")
            .setScale(0.3)
            .setDepth(6)
            .setAlpha(0);
          this.add;
          this.monstor.dizzy = this.add
            .sprite(this.startPlot.x, this.startPlot.y, "sptDizzyAnim")
            .setFrame(2)
            .setDepth(6)
            .setOrigin(0.5, 1)
            .setScale(0.8);
          this.monstor.effect = this.add
            .sprite(this.monstor.x, this.monstor.y, "sptSmoke")
            .setScale(0.5)
            .setDepth(7)
            .setFrame(7);
          this.monstor.play("animBossL");
          this.monstor.effect.play("animSmoke");
          this.time.delayedCall(1500, () => {
            this.monstor.setAlpha(1);
            this.monstor.effect.setFrame(7);
          });
        } else if (!isMonsterBack) {
          this.monstorFollow();
          this.monstorPlot = this.previousPlot;
        }
      }

      if (this.playerMask) {
        this.playerMask.destroy();
      }
      if (this.plots[this.currentPlot.i + 1])
        this.plots[this.currentPlot.i + 1].forEach((plot) => {
          if (
            plot.j == this.currentPlot.j ||
            (plot.j == this.currentPlot.j - 1 && plot.isConnectedR) ||
            (plot.j == this.currentPlot.j + 1 && plot.isConnectedL)
          ) {
            this.activatePlot(plot);
          }
        });
      else
        this.endPlot
          .setInteractive({ useHandCursor: true })
          .on("pointerdown", this.moveToEnd, this)
          .setFrame(1);

      if (this.plots[this.currentPlot.i - 1] && this.isBackward)
        this.plots[this.currentPlot.i - 1].forEach((plot) => {
          if (
            plot.j == this.currentPlot.j ||
            (plot.j == this.currentPlot.j - 1 &&
              this.currentPlot.isConnectedL) ||
            (plot.j == this.currentPlot.j + 1 && this.currentPlot.isConnectedR)
          ) {
            this.activatePlot(plot);
          }
        });

      if (this.plots[this.currentPlot.i] && this.isHorizontal)
        this.plots[this.currentPlot.i].forEach((plot) => {
          if (
            plot.j == this.currentPlot.j - 1 ||
            plot.j == this.currentPlot.j + 1
          ) {
            this.activatePlot(plot);
          }
        });
    }
  }

  activatePlot(plot) {
    if (plot != this.monstorPlot) {
      if (plot.card)
        plot.card
          .setInteractive({ useHandCursor: true })
          .on("pointerdown", () => this.movePlayer(plot), this)
          .setFrame(1);
      else
        plot.node
          .setInteractive({ useHandCursor: true })
          .on("pointerdown", () => this.movePlayer(plot), this);

      plot.node.setFrame(1);
    }
  }

  // actions
  bombing() {
    let mgob = this.add
      .sprite(this.avatarObject[0].x, this.avatarObject[0].y, "sptBlast")
      .setScale(0.6)
      .setDepth(6)
      .setFrame(7);

    mgob.play("animBlast");
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskScold")
      .setScale(0.15)
      .setDepth(7);
    mgob.on("animationcomplete", () => {
      mgob.destroy();
      if (this.playerMask) {
        this.playerMask.destroy();
      }
      this.reduceHeart();
    });
  }

  reduceHeart(isDie = false) {
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskSad")
      .setScale(0.15)
      .setDepth(7);

    this.rewardIndication(
      this.currentPlot.x,
      this.currentPlot.y,
      isDie ? this.lifeCount : 1,
      false,
      "imgHeart"
    );
    this.time.delayedCall(1000, () => {
      this.lifeCount = isDie ? 0 : this.lifeCount - 1;
      this.heartImgs[this.lifeCount].setAlpha(0.2).setTint(0x000);
      // this.heartText.setText("X " + this.lifeCount);

      if (this.lifeCount == 0) {
        this.add
          .rectangle(240, 427, 480, 854, 0x000000)
          .setAlpha(0.5)
          .setDepth(8);
        this.add
          .text(240, this.cameras.main.scrollY + 500, "Game Over", {
            color: "#ffffff",
            fontSize: "50px",
            fontFamily: "Sassoon, sans-serif",
            stroke: "#000000",
            strokeThickness: 5,
          })
          .setDepth(8)
          .setShadow(3, 3, "#000", 0, true, true)
          .setOrigin(0.5);
        this.time.delayedCall(1500, () => {
          router.go();
        });
      } else {
        this.time.delayedCall(1500, () => {
          this.activeNextCards();
        });
      }
    });
  }

  increaseHeart() {
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskSad")
      .setScale(0.15)
      .setDepth(7);

    this.rewardIndication(
      this.currentPlot.x,
      this.currentPlot.y,
      3 - this.lifeCount,
      true,
      "imgHeart"
    );
    this.time.delayedCall(1000, () => {
      this.lifeCount = 3;
      this.heartImgs.forEach((heart) => {
        heart.setAlpha(1).setTint(0xffffff);
      });

      this.time.delayedCall(1500, () => {
        this.activeNextCards();
      });
    });
  }

  reduceCoin(amount) {
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskSad")
      .setScale(0.15)
      .setDepth(7);

    this.rewardIndication(
      this.currentPlot.x,
      this.currentPlot.y,
      amount,
      false,
      "imgCoin"
    );
    this.time.delayedCall(1000, () => {
      this.coinCount -= amount;
      // this.coinsText.setText(this.coinCount);

      this.time.delayedCall(1500, () => {
        this.activeNextCards();
      });
    });
  }

  increaseCoin(amount, isActivate = false) {
    if (this.playerMask) {
      this.playerMask.destroy();
    }
    this.playerMask = this.add
      .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappy")
      .setScale(0.15)
      .setDepth(7);

    this.rewardIndication(
      this.currentPlot.x,
      this.currentPlot.y,
      amount,
      true,
      "imgCoin"
    );
    this.time.delayedCall(1000, () => {
      this.coinCount += amount;
      // this.coinsText.setText(this.coinCount);

      if (isActivate)
        this.time.delayedCall(1500, () => {
          this.activeNextCards();
        });
    });
  }

  reduceSteps() {
    this.rewardIndication(
      this.currentPlot.x,
      this.currentPlot.y,
      1,
      false,
      "imgSteps"
    );
    this.time.delayedCall(1000, () => {
      this.stepsCount -= 1;
      this.stepsText.setText("X " + this.stepsCount);
    });
  }

  moveToEnd() {
    this.tweens.add({
      targets: this.avatarObject,
      x: 240,
      y: 115,
      ease: "Power1",
      duration: 400,
      onComplete: () => {
        this.time.delayedCall(1000, () => {
          this.cameras.main.fadeOut(1000, 0, 0, 0);
          this.scene.stop("MapScene");
          router.go();
        });
      },
    });
  }

  rewardIndication(x, y, score, increase = true, image) {
    if (increase) {
      if (this.playerMask) {
        this.playerMask.destroy();
      }
      this.playerMask = this.add
        .image(this.avatarObject[0].x, this.avatarObject[0].y, "maskHappy")
        .setScale(0.15)
        .setDepth(7);
    }
    let indicationImg = this.add
      .image(score == 0 ? x : x - 20, y - 70, image)
      .setScale(0.6)
      .setDepth(99);
    let indication;
    if (score != 0) {
      indication = this.add
        .text(x + 10, y - 70, increase ? "+ " + score : "- " + score, {
          color: increase ? "#000" : "#ff0000",
          fontSize: "20px",
          fontFamily: "Sassoon, sans-serif",
        })
        .setOrigin(0.5)
        .setDepth(99);
    }
    this.tweens.add({
      targets: [indicationImg, indication],
      alpha: 0,
      y: "-=50",
      delay: 500,
      duration: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        // this.sfxScoreIncrease.setVolume(
        //   store.state.settings.data.audio.sfx *
        //     store.state.settings.data.audio.master
        // );
        // this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        if (indication) indication.destroy();
        indicationImg.destroy();
      },
    });
  }

  scoreIndication(x, y, score, increase = true) {
    let indication = this.add
      .text(x, y - 50, increase ? "+ " + score : "- " + score, {
        color: increase ? "#fff" : "#ff0000",
        fontSize: "20px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0.5)
      .setDepth(9);
    this.tweens.add({
      targets: indication,
      alpha: 0,
      y: "-=50",
      duration: 500,
      ease: "Sine.easeInOut",
      onStart: () => {
        // this.sfxScoreIncrease.setVolume(
        //   store.state.settings.data.audio.sfx *
        //     store.state.settings.data.audio.master
        // );
        // this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        indication.destroy();
        // this.score += score;
        // this.scoreText.setText(
        //   i18n.t("miniGames.general.score") + " : " + this.score
        // );
      },
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "MapScene",
    });
  }

  clickClose() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.stop("MapScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    router.go();
  }

  registerAnims() {
    this.anims.create({
      key: "animBlast",
      frames: this.anims.generateFrameNumbers("sptBlast", {
        frames: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 6, 7],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "animBossL",
      frames: this.anims.generateFrameNumbers("sptBoss", {
        frames: [0, 1],
      }),
      frameRate: 1,
      repeat: -1,
    });

    // this.anims.create({
    //   key: "avatarPower",
    //   frames: this.anims.generateFrameNumbers("sptShield", {
    //     frames: [2, 3, 4],
    //   }),
    //   frameRate: 5,
    //   repeat: -1,
    // });

    this.anims.create({
      key: "animDizzy",
      frames: this.anims.generateFrameNumbers("sptDizzyAnim", {
        frames: [0, 1, 0, 1, 0, 1, 2],
      }),
      frameRate: 4,
    });

    this.anims.create({
      key: "animAttackFire",
      frames: this.anims.generateFrameNumbers("sptAttackFire", {
        frames: [1, 2, 1, 2, 3, 4, 3, 4, 4, 4, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animAttackThunder",
      frames: this.anims.generateFrameNumbers("sptAttackThunder", {
        frames: [1, 2, 1, 2, 3, 4, 3, 4, 4, 4, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animMiniAttackThunder",
      frames: this.anims.generateFrameNumbers("sptMiniAttackThunder", {
        frames: [1, 2, 1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 0],
      }),
      frameRate: 8,
    });

    this.anims.create({
      key: "animAttackWater",
      frames: this.anims.generateFrameNumbers("sptAttackWater", {
        frames: [1, 2, 1, 2, 3, 4, 5, 4, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 8,
    });

    // this.anims.create({
    //   key: "animBlast",
    //   frames: this.anims.generateFrameNumbers("sptBlast", {
    //     frames: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 5, 6, 7],
    //   }),
    //   frameRate: 15,
    // });

    this.anims.create({
      key: "animSmoke",
      frames: this.anims.generateFrameNumbers("sptSmoke", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6],
      }),
      frameRate: 5,
    });
    this.anims.create({
      key: "animSmokeI",
      frames: this.anims.generateFrameNumbers("sptSmoke", {
        frames: [3, 4, 5, 4, 3, 4, 5],
      }),
      frameRate: 5,
      repeat: -1,
    });

    // this.anims.create({
    //   key: "animBomb",
    //   frames: this.anims.generateFrameNumbers("sptBomb", {
    //     frames: [0, 1, 2, 3],
    //   }),
    //   frameRate: 10,
    //   repeat: -1,
    // });
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
  }

  registerCards() {
    this.chestCards = [
      {
        type: "chest",
        title: "Chest",
        image: "imgCC1",
        description: "It's a treasure  chest. Open it by...",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.dangerCards = [
      {
        type: "danger",
        title: "Danger",
        image: "imgCC1",
        description: "It's a treasure  chest. Open it by...",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.trumpCards = [
      {
        type: "trump",
        title: "Extra Life",
        gain: "heart",
        image: "imgTC1",
        description:
          "You got a additional Life, to collect it answer a question.",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "trump",
        title: "Chest Key",
        gain: "key",
        image: "imgTC2",
        description:
          "You got a Chest key. It can use to open treasure chest, to collect it answer a question.",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "trump",
        title: "Plier",
        gain: "plier",
        image: "imgTC3",
        description:
          "You got a Plier. It can use to defuse bomb, to collect it answer a question.",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "trump",
        title: "Doubler",
        gain: "x2",
        image: "imgTC4",
        description:
          "You got a Doubler. If you win rewards, they'll be doubled, to collect it answer a question.",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "trump",
        title: "Bomb Replacer",
        gain: "swapB",
        image: "imgTC5",
        description:
          "You got a Bomb Replacer. It will replace all bomb by Gem, to collect it answer a question.",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.bombCards = [
      {
        type: "bomb",
        title: "Bomb",
        image: "imgBC1",
        description: "It's a Bomb. Try to defuse it by...",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.monstorAttackCard = {
      type: "monstor",
      title: "Monstor Attack",
      image: "imgMC1",
      description: "Time exceeded. the monster attacks you!",
      options: [
        { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
        { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
        // { title: "Solve a Puzzle", icon: "", key: "" },
      ],
    };

    this.enemyAttackCard = {
      type: "enemy",
      title: "Thief Attack",
      image: "imgThC1",
      description: "A thief got confused and attacks you!",
      options: [
        { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
        { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
        // { title: "Solve a Puzzle", icon: "", key: "" },
      ],
    };

    this.thiefAttackCard = [
      {
        type: "thief",
        title: "Thief Attack",
        image: "imgThC1",
        description: "A thief attacks you and tries to rob you!",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.fillerCards = [
      {
        type: "filler",
        title: "Gem",
        image: "imgFC1",
        description: "It's a small gem. Collect it by...",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];

    this.eventCards = [
      {
        type: "event",
        xtype: "negative",
        title: "Encountered a snake",
        image: "imgT1",
        description:
          "Oh... a snake bit you in the bamboo forest... find a medic?",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "event",
        xtype: "positive",
        title: "Misterious treasure ",
        image: "imgT2",
        description:
          "Found a mysterious treasure chest in the bamboo forest... Want to open it?",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
      {
        type: "event",
        xtype: "positive",
        title: "Misterious treasure ",
        image: "imgT2",
        description:
          "Found a mysterious treasure chest in the bamboo forest... Want to open it?",
        options: [
          { title: "1 X ABC Fill in Blanks", icon: "", key: "ABC_1" },
          { title: "3 X ABC Multiple Choice", icon: "", key: "ABC_2" },
          // { title: "Solve a Puzzle", icon: "", key: "" },
        ],
      },
    ];
  }

  // Util functions
  getRandom(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  getRandomAndRemove(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    const randomElement = array[randomIndex];
    array.splice(randomIndex, 1);
    return randomElement;
  }
}
