import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import axios from "axios";
import store from "@/store";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image(
      "imgBackBtn",
      "/assets/gameAssets/common/images/btn-back.png"
    );
    this.load.image(
      "imgNextBtn",
      "/assets/gameAssets/common/images/btn-next.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");

    // images specific to the game
    this.load.image(
      "imgBackgroundGreen",
      "/assets/gameAssets/abc/images/background.png"
    );

    this.load.image(
      "imgBackground",
      "/assets/gameAssets/abc/images/background.png"
    );

    this.load.image(
      "imgPurchased",
      "/assets/gameAssets/abc/images/purchased.png"
    );

    this.load.image("imgBook", "/assets/gameAssets/abc/images/book.png");

    this.load.image(
      "imgReportCard",
      "/assets/gameAssets/abc/images/report_card.png"
    );
    this.load.image("imgTown", "/assets/gameAssets/abc/images/town.png");
    this.load.image("imgMpc1", "/assets/gameAssets/abc/images/mpc_1.png");
    this.load.image("imgMpc2", "/assets/gameAssets/abc/images/mpc_2.png");
    this.load.image("imgChapter", "/assets/gameAssets/abc/images/chapter.png");

    

    this.load.image("imgFinish", "/assets/gameAssets/abc/images/finish.png");

    this.load.image("imgNode", "/assets/gameAssets/abc/images/node.png");

    this.load.image(
      "imgChapterPanel",
      "/assets/gameAssets/abc/images/chapter_panel.png"
    );

    this.load.image("imgStar", "/assets/gameAssets/abc/images/C000040.png");
    this.load.image("imgLock", "/assets/gameAssets/abc/images/lock.png");
    this.load.image(
      "imgBookPage",
      "/assets/gameAssets/abc/images/book_page.png"
    );
    this.load.image(
      "imgFreezeMochi",
      "/assets/gameAssets/abc/images/hamochi_freeze.png"
    );
    this.load.image("imgThumb", "/assets/gameAssets/abc/images/thumb.png");

    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // Specific
    this.load.spritesheet(
      "sptStar",
      "/assets/gameAssets/abc/sprites/star.png",
      {
        frameWidth: 89,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "audioButton",
      "/assets/gameAssets/abc/sprites/button-audio.png",
      {
        frameWidth: 60,
        frameHeight: 60,
      }
    );
    this.load.spritesheet(
      "sptBookMark",
      "/assets/gameAssets/abc/sprites/book-mark.png",
      {
        frameWidth: 150,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptLock",
      "/assets/gameAssets/abc/sprites/lock.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");

    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
    this.load.image("maskAttack", "/assets/gameAssets/common/masks/M0090.png");

    this.load.image("maskBoss", "/assets/gameAssets/common/masks/M1000.png");
    this.load.image(
      "maskBossHurt",
      "/assets/gameAssets/common/masks/M1002.png"
    );
    this.load.image(
      "maskBossHappy",
      "/assets/gameAssets/common/masks/M1003.png"
    );
    this.load.image(
      "maskBossAttack",
      "/assets/gameAssets/common/masks/M1004.png"
    );

    this.load.image(
      "imgOptionPanel",
      "/assets/gameAssets/abc/images/option-panel.png"
    );
    

    // API Call
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let callback = axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/zh/game/abc/book/browse`,
        {},
        { headers }
      )
      .then((resp) => {
        this.books = resp.data.Book;
        this.books.forEach((book) => {
          this.load.image(book.key, book.thumbnail.url);
        });
        console.log(this.books);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    this.load.rexAwait(callback);
  }

  create() {
    this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("BooksScene", {
      avatar: this.avatar,
      env: this.env,
      vocab: this.vocab,
      books: this.books,
    });
  }
}
