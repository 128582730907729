import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.isBackward = data.isBackward;
    this.isHorizontal = data.isHorizontal;
    this.isLife = data.isLife;
    this.isSteps = data.isSteps;
    this.isTime = data.isTime;
    this.lifeCount = data.lifeCount;
    this.stepsCount = data.stepsCount;
    this.timeCount = data.timeCount;
    this.timeChallenge = data.timeChallenge;
    this.cardDetails = data.cardDetails;
    this.isMonsterChase = data.isMonsterChase;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image(
      "imgBackBtn",
      "/assets/gameAssets/common/images/btn-back.png"
    );
    this.load.image(
      "imgNextBtn",
      "/assets/gameAssets/common/images/btn-next.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");

    // images specific to the game
    this.load.image(
      "imgTileForest",
      "/assets/gameAssets/abc/images/tile_forest.png"
    );
    this.load.image(
      "imgTileSoil",
      "/assets/gameAssets/abc/images/tile_soil.png"
    );
    this.load.image(
      "imgTileWater",
      "/assets/gameAssets/abc/images/tile_water.png"
    );
    this.load.image(
      "imgTileGrass",
      "/assets/gameAssets/abc/images/tile_grass.png"
    );
    this.load.image(
      "imgTileStart",
      "/assets/gameAssets/abc/images/tile_start.png"
    );
    this.load.image("imgTileEnd", "/assets/gameAssets/abc/images/tile_end.png");

    this.load.image("imgFinish", "/assets/gameAssets/abc/images/finish.png");

    this.load.image("imgNode", "/assets/gameAssets/abc/images/node-x.png");

    this.load.image(
      "imgOptionPanel",
      "/assets/gameAssets/abc/images/option-panel.png"
    );
    this.load.image("imgC1", "/assets/gameAssets/abc/images/c-1.png");
    this.load.image("imgC2", "/assets/gameAssets/abc/images/c-2.png");
    this.load.image("imgC3", "/assets/gameAssets/abc/images/c-3.png");
    this.load.image("imgT1", "/assets/gameAssets/abc/images/t-1.png");
    this.load.image("imgT2", "/assets/gameAssets/abc/images/t-2.png");
    this.load.image("imgT3", "/assets/gameAssets/abc/images/t-3.png");
    this.load.image("imgD1", "/assets/gameAssets/abc/images/d-1.png");
    this.load.image("imgD2", "/assets/gameAssets/abc/images/d-2.png");
    this.load.image("imgD3", "/assets/gameAssets/abc/images/d-3.png");

    this.load.image("imgTC1", "/assets/gameAssets/abc/images/tc-1.png");
    this.load.image("imgTC2", "/assets/gameAssets/abc/images/tc-2.png");
    this.load.image("imgTC3", "/assets/gameAssets/abc/images/tc-3.png");
    this.load.image("imgTC4", "/assets/gameAssets/abc/images/tc-4.png");
    this.load.image("imgTC5", "/assets/gameAssets/abc/images/tc-5.png");

    this.load.image("imgBC1", "/assets/gameAssets/abc/images/bc-1.png");

    this.load.image("imgCC1", "/assets/gameAssets/abc/images/cc-1.png");

    this.load.image("imgFC1", "/assets/gameAssets/abc/images/fc-1.png");
    this.load.image("imgMC1", "/assets/gameAssets/abc/images/monstor.png");
    this.load.image("imgThC1", "/assets/gameAssets/abc/images/thief.png");

    this.load.image("imgHeart", "/assets/gameAssets/abc/images/heart.png");
    this.load.image("imgSteps", "/assets/gameAssets/abc/images/steps.png");
    this.load.image("imgPlier", "/assets/gameAssets/abc/images/plier.png");
    this.load.image("imgKey", "/assets/gameAssets/abc/images/key.png");
    this.load.image("imgX2", "/assets/gameAssets/abc/images/x2.png");

    this.load.image("imgCoin", "/assets/gameAssets/abc/images/coin.png");
    this.load.image("imgPotion", "/assets/gameAssets/abc/images/potion.png");

    this.load.image("imgGem", "/assets/gameAssets/abc/images/gem.png");

    this.load.image("imgCO", "/assets/gameAssets/abc/images/chest-open.png");

    this.load.image("imgDie", "/assets/gameAssets/abc/images/die.png");
    // puzzel scene
    this.load.image("imgPuzzBg", "/assets/gameAssets/abc/images/pz-bg.png");

    this.load.image("imgPZS", "/assets/gameAssets/abc/images/pzs.png");

    this.load.image("imgPZT", "/assets/gameAssets/abc/images/pzt.png");
    this.load.image("imgEny", "/assets/gameAssets/abc/images/eny.png");

    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // Specific
    this.load.spritesheet(
      "sptCardChest",
      "/assets/gameAssets/abc/sprites/card-chest.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardDie",
      "/assets/gameAssets/abc/sprites/card-die.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardHide",
      "/assets/gameAssets/abc/sprites/card-hide.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardTrump",
      "/assets/gameAssets/abc/sprites/card-trump.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardBomb",
      "/assets/gameAssets/abc/sprites/card-bomb.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardEvent",
      "/assets/gameAssets/abc/sprites/card-event.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardFiller",
      "/assets/gameAssets/abc/sprites/card-filler.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardIdea",
      "/assets/gameAssets/abc/sprites/card-idea.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCardThief",
      "/assets/gameAssets/abc/sprites/card-thief.png",
      {
        frameWidth: 60,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptBlast",
      "/assets/gameAssets/abc/sprites/bomb-blast.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptNode",
      "/assets/gameAssets/abc/sprites/node.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptBlast",
      "/assets/gameAssets/kombatGame/sprites/bomb-blast.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptSmoke",
      "/assets/gameAssets/kombatGame/sprites/smoke.png",
      {
        frameWidth: 250,
        frameHeight: 250,
      }
    );

    this.load.spritesheet(
      "sptAttackFire",
      "/assets/gameAssets/kombatGame/sprites/attack-fire.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptAttackThunder",
      "/assets/gameAssets/kombatGame/sprites/attack-thunder.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptMiniAttackThunder",
      "/assets/gameAssets/kombatGame/sprites/attack-mini-thunder.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptAttackWater",
      "/assets/gameAssets/kombatGame/sprites/attack-water.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptBoss",
      "/assets/gameAssets/cookingGame/sprites/boss.png",
      {
        frameWidth: 320,
        frameHeight: 280,
      }
    );

    this.load.spritesheet(
      "sptDizzyAnim",
      "/assets/gameAssets/kombatGame/sprites/dizzy.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");

    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // // load friend avatar data
    // for (var _key of Object.keys(this.friend.avatar)) {
    //   if (this.friend.avatar[_key] && _key != "color") {
    //     this.load.image(_key, this.friend.avatar[_key]);
    //   }
    // }

    // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
    this.load.image("maskAttack", "/assets/gameAssets/common/masks/M0090.png");
    this.load.image("maskBoss", "/assets/gameAssets/common/masks/M1000.png");
    this.load.image(
      "maskBossHurt",
      "/assets/gameAssets/common/masks/M1002.png"
    );
    this.load.image(
      "maskBossHappy",
      "/assets/gameAssets/common/masks/M1003.png"
    );
    this.load.image(
      "maskBossAttack",
      "/assets/gameAssets/common/masks/M1004.png"
    );

    //* kombat

    // images specific to the game
    this.load.image(
      "imgBackground",
      "/assets/gameAssets/abc/images/bg-kombat.png"
    );

    this.load.image(
      "imgEny1",
      "/assets/gameAssets/kombatGame/images/eny-1.png"
    );

    this.load.image("imgWand", "/assets/gameAssets/kombatGame/images/wand.png");

    // Specific
    // Kombat
    this.load.spritesheet(
      "sptAttackBtn",
      "/assets/gameAssets/kombatGame/sprites/attack-btn.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptAttackAnim",
      "/assets/gameAssets/kombatGame/sprites/attack-anim.png",
      {
        frameWidth: 200,
        frameHeight: 200,
      }
    );

    this.load.spritesheet(
      "sptDizzyAnim",
      "/assets/gameAssets/kombatGame/sprites/dizzy.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptWand",
      "/assets/gameAssets/kombatGame/sprites/wand.png",
      {
        frameWidth: 100,
        frameHeight: 150,
      }
    );

    this.load.spritesheet(
      "sptMgOb1",
      "/assets/gameAssets/kombatGame/sprites/mgob-1.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptAttackFire",
      "/assets/gameAssets/kombatGame/sprites/attack-fire.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptMiniAttackFire",
      "/assets/gameAssets/kombatGame/sprites/attack-mini-fire.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptAttackThunder",
      "/assets/gameAssets/kombatGame/sprites/attack-thunder.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptMiniAttackThunder",
      "/assets/gameAssets/kombatGame/sprites/attack-mini-thunder.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptAttackWater",
      "/assets/gameAssets/kombatGame/sprites/attack-water.png",
      {
        frameWidth: 250,
        frameHeight: 300,
      }
    );

    this.load.spritesheet(
      "sptAttackMagic",
      "/assets/gameAssets/kombatGame/sprites/attack-magic.png",
      {
        frameWidth: 300,
        frameHeight: 250,
      }
    );

    this.load.spritesheet(
      "sptMiniAttackMagic",
      "/assets/gameAssets/kombatGame/sprites/attack-mini-magic.png",
      {
        frameWidth: 300,
        frameHeight: 250,
      }
    );

    this.load.spritesheet(
      "sptWandPower",
      "/assets/gameAssets/kombatGame/sprites/wand-power.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptKnife",
      "/assets/gameAssets/kombatGame/sprites/knife.png",
      {
        frameWidth: 50,
        frameHeight: 50,
      }
    );

    this.load.spritesheet(
      "sptPlayerAttacks",
      "/assets/gameAssets/kombatGame/sprites/player-attacks.png",
      {
        frameWidth: 150,
        frameHeight: 150,
      }
    );

    this.load.spritesheet(
      "sptShield",
      "/assets/gameAssets/kombatGame/sprites/shield.png",
      {
        frameWidth: 300,
        frameHeight: 300,
      }
    );

    // sfx specific to the game
    this.load.audio("bgm", "/assets/gameAssets/kombatGame/sounds/bgm.mp3");
    this.load.audio("sfxFire", "/assets/gameAssets/kombatGame/sounds/fire.wav");
    this.load.audio(
      "sfxThunder",
      "/assets/gameAssets/kombatGame/sounds/thunder.wav"
    );
    this.load.audio(
      "sfxWater",
      "/assets/gameAssets/kombatGame/sounds/water.wav"
    );
    this.load.audio(
      "sfxMagic",
      "/assets/gameAssets/kombatGame/sounds/magic.wav"
    );
    this.load.audio("sfxBoss", "/assets/gameAssets/kombatGame/sounds/boss.wav");
    this.load.audio(
      "sfxSmoke",
      "/assets/gameAssets/kombatGame/sounds/smoke.wav"
    );
    this.load.audio("sfxCry", "/assets/gameAssets/kombatGame/sounds/cry.wav");
    this.load.audio("sfxBomb", "/assets/gameAssets/kombatGame/sounds/bomb.wav");
    this.load.audio(
      "sfxThrow",
      "/assets/gameAssets/kombatGame/sounds/throw.wav"
    );
    this.load.audio("sfxHit", "/assets/gameAssets/kombatGame/sounds/hit.mp3");
    this.load.audio(
      "sfxNoti1",
      "/assets/gameAssets/kombatGame/sounds/noti-1.wav"
    );
    this.load.audio(
      "sfxNoti2",
      "/assets/gameAssets/kombatGame/sounds/noti-2.wav"
    );
    this.load.audio(
      "sfxNoti3",
      "/assets/gameAssets/kombatGame/sounds/noti-3.wav"
    );
    this.load.audio(
      "sfxBossSuccess",
      "/assets/gameAssets/kombatGame/sounds/boss-success.wav"
    );
    this.load.audio(
      "sfxBossFail",
      "/assets/gameAssets/kombatGame/sounds/boss-fail.wav"
    );
    this.load.audio(
      "sfxCollect",
      "/assets/gameAssets/kombatGame/sounds/collect.wav"
    );
    this.load.audio("sfxLost", "/assets/gameAssets/kombatGame/sounds/lost.wav");
    this.load.audio("sfxWon", "/assets/gameAssets/kombatGame/sounds/won.wav");
    this.load.audio("sfxWin", "/assets/gameAssets/kombatGame/sounds/win.wav");
    this.load.audio(
      "sfxBubble",
      "/assets/gameAssets/kombatGame/sounds/bubble.wav"
    );
    this.load.audio(
      "sfxChest",
      "/assets/gameAssets/kombatGame/sounds/chest.wav"
    );
    this.load.audio(
      "sfxReady",
      "/assets/gameAssets/kombatGame/sounds/ready.wav"
    );
    this.load.audio("sfxGo", "/assets/gameAssets/kombatGame/sounds/go.wav");
    this.load.audio(
      "sfxRound",
      "/assets/gameAssets/kombatGame/sounds/round.wav"
    );
    this.load.audio(
      "sfxCharge",
      "/assets/gameAssets/kombatGame/sounds/charge.wav"
    );
    this.load.audio(
      "sfxStrong",
      "/assets/gameAssets/kombatGame/sounds/strong.wav"
    );
    this.load.audio("sfxWeak", "/assets/gameAssets/kombatGame/sounds/weak.wav");
    this.load.audio(
      "sfxLanding",
      "/assets/gameAssets/kombatGame/sounds/landing.wav"
    );

    this.load.audio(
      "sfxShieldPop",
      "/assets/gameAssets/kombatGame/sounds/shield-pop.mp3"
    );
    this.load.audio(
      "sfxShieldHit",
      "/assets/gameAssets/kombatGame/sounds/shield-hit.mp3"
    );
  }

  create() {
    this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("MapScene", {
      avatar: this.avatar,
      env: this.env,
      vocab: this.vocab,
      isBackward: this.isBackward,
      isHorizontal: this.isHorizontal,
      isLife: this.isLife,
      isSteps: this.isSteps,
      isTime: this.isTime,
      lifeCount: this.lifeCount,
      stepsCount: this.stepsCount,
      timeCount: this.timeCount,
      timeChallenge: this.timeChallenge,
      cardDetails: this.cardDetails,
      isMonsterChase: this.isMonsterChase,
    });
  }
}
