import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";
// import router from "@/router";
// import _ from "lodash";

export default class RestartScene extends Scene {
  constructor() {
    super({ key: "RestartScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.vocab = data.vocab;
    this.env = data.env;
    this.chapter = data.chapter;
    this.book = data.book;
    this.books = data.books;
    this.chapters = data.chapters;
    this.steps = data.steps;
    this.currentStep = data.currentStep;
  }

  create() {
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.closeBtn = this.add
      .image(40, 40, "imgBackBtn")
      .setScale(0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickClose, this);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.add
      .text(240, 280, "You Failed!", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 36,
        color: "#000",
      })
      .setOrigin(0.5);

    // this.add
    //   .text(240, 300, "You Failed!", {
    //     fontFamily: "WarungKopi, sans-serif",
    //     fontSize: 24,
    //     color: "#000",
    //   })
    //   .setOrigin(0.5)
    //   .setScale(1);

    this.add
      .image(240, 370, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.retry(), this);

    this.add
      .text(235, 370, "Try again", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);

    this.add
      .image(240, 440, "imgOkBtn")
      .setScale(0.6)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", () => this.clickClose(), this);

    this.add
      .text(235, 440, "Take a break", {
        fontFamily: "MouseMemoirs, sans-serif",
        fontSize: 28,
        color: "#000",
      })
      .setOrigin(0.5);
  }

  retry(){
    this.scene.stop("RestartScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);

    this.scene.start("ChapterIntroScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapter: this.chapter,
      book: this.book,
      books: this.books,
      chapters: this.chapters,
    });
  }

  clickClose() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.stop("RestartScene");
    this.cameras.main.fadeOut(1000, 0, 0, 0);
    this.scene.start("ChaptersScene", {
      avatar: this.avatar,
      vocab: this.vocab,
      env: this.env,
      chapters: this.chapters,
      book: this.book,
      books: this.books,
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "RestartScene",
    });
  }
  update() {}
}
